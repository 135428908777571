import React, { useContext, useEffect } from "react";

import Label from "./label";
import Input from "./input";
import LineMsg from "./lineMsg";

import { strings } from "../../util/formatter";
import { validateAmount } from "../../util/validator";
import { SessionContext } from "../../util/context";

const ManageWalletDeposit = ({ walletForm, setWalletForm }) =>
{
    const { prefs } = useContext(SessionContext);
    const {
        deposit
    } = walletForm || {};

    const {
        amount
    } = deposit || {};

    const onValueChange = (value, key) =>
    {
        setWalletForm({ ...walletForm, deposit: { ...deposit, [key]: value } });
        if (key === "amount") window?.Moyasar.setAmount(value ? value * 100 : 100 * 100);
    };

    useEffect(() =>
    {
        const observer = new MutationObserver(() =>
        {
            const elements = document.querySelectorAll(".mysr-form-input");

            elements.forEach(element => {
                element.setAttribute("theme", prefs?.theme);
                element.style.setProperty("height", "auto", "important");
                element.style.setProperty("padding", "16px 1rem 16px 1rem", "important");
            });
        });
        observer.observe(document.body, { childList: true, subtree: true });
        return () => observer.disconnect();
    }, [])

    return (
        <>
            <div className="pb-grid-sm">
                <Label label={strings("label.amount")}/>
                <Input
                    validateLength={true}
                    maxLength={6}
                    name="amount"
                    value={amount}
                    setValue={(e) => { onValueChange(e, "amount") }}
                />
                <LineMsg msg={validateAmount(walletForm?.deposit?.amount)?.msg} type="error"/>
            </div>
            <div>
                <p className="text-md">{strings("label.nameOnCard")}</p>
                <div id={`form-payment-credit-wallet-${prefs?.dir}`}>
                    <div className="form-payment-credit-wallet"/>
                </div>
            </div>
        </>
    );
};

export default ManageWalletDeposit;