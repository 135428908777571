import React, { useContext, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import Section from "../view/component/section";
import SVGIcon from "../view/component/svgIcons";
import Loader from "../view/component/loader";

import { SessionContext } from "../util/context";
import { getDate, strings } from "../util/formatter";
import { request } from "../util/http";
import constant from "../config/constant";
import endpoint from "../config/endpoint";

const StaticPage = () =>
{
    const { prefs } = useContext(SessionContext);
    const params = useParams();
    const [activeIndex, setActiveIndex] = useState(-1);
    const [page, setPage] = useState({});
    const [faqList, setFaqList] = useState([]);
    const [isLoading, setIsLoading] = useState(-1);

    const fetchPage = async () =>
    {
        setIsLoading(true);
        try
        {
            const res = await request("GET", `${endpoint.cms.page.data}?page_code=${params?.id?.replace("-", "_")}`);
            setPage(res?.info);
            setIsLoading(false);
        }
        catch (error) {
            console.log(error)
            setIsLoading(false);
        }
    };

    const fetchFAQ = async () =>
    {
        setIsLoading(true);
        try
        {
            const res = await request("GET", `${endpoint.cms.faq.list}`);
            setFaqList(res?.list);
            setIsLoading(false);
        }
        catch (error) {
            console.log(error)
            setIsLoading(false);
        }
    };

    useEffect(() =>
    {
        if (params?.id === "FAQ")
        {
            fetchFAQ();
        }
        else
        {
            fetchPage();
        }
    }, [params?.id]);

    return (
        <Section optionalStyle="min-h-[calc(100vh-474px)]">
            {isLoading && <Loader />}
            <div className="relative pt-[60%] sm:pt-[40%] md:pt-[35%] lg:pt-[20%]">
                {params?.id === "FAQ"
                    ? <>
                        <p className="mb-grid-lg px-grid-lg md:px-0 md:container mx-auto text-xl font-primary text-primary-lightest">
                            {strings("title.FAQ")}
                        </p>
                        {faqList?.sort((a, b) => a.sort_id - b.sort_id)?.map((item, index) => (
                            <div key={index}>
                                <p className="px-grid-lg md:px-0 md:container mx-auto mb-grid-lg font-primary text-primary-lightest text-base">{prefs?.lang === constant.lang.values[0] ? item?.section_title_ar : item?.section_title_en}</p>
                                {item?.content?.sort((a, b) => a.sort_id - b.sort_id)?.map((qa, questionIndex) => (
                                    <div
                                        key={`${index}_${questionIndex}`}
                                        className={`h-auto mx-grid-lg md:mx-auto md:container mt-grid-md ${questionIndex === faqList[index]?.content?.length - 1 && "mb-[100px]"} p-grid-sm lg:p-grid-lg bg-primary-lightest text-secondary-darkest text-sm font-secondary rounded-sm shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)]`}
                                    >
                                        <div className={`flex gap-grid-xs justify-between cursor-pointer`} onClick={() => setActiveIndex(activeIndex !== `${index}_${questionIndex}` ? `${index}_${questionIndex}` : -1)}>
                                            <p className="flex gap-grid-xs items-center font-primary text-base">
                                                {prefs?.lang === constant.lang.values[0] ? qa?.question_ar : qa?.question_en}
                                            </p>
                                            <div className="flex items-center">
                                                <SVGIcon name="chevron" viewBox="0 0 24 24" width={17} height={17} stroke="stroke-secondary-darkest" className={`${activeIndex !== `${index}_${questionIndex}` ? "-rotate-90" : "rotate-90"} fill-none`}/>
                                            </div>
                                        </div>
                                        <AnimatePresence>
                                            {activeIndex === `${index}_${questionIndex}` &&
                                            (
                                                <motion.div
                                                    style={{ overflow: "hidden" }}
                                                    initial={{ height: 0 }}
                                                    animate={{ height: "auto" }}
                                                    transition={{ duration: 0.5 }}
                                                    exit={{ height: 0 }}
                                                >
                                                    <p className="pt-grid-sm text-base">
                                                        {prefs?.lang === constant.lang.values[0] ? qa?.answer_ar : qa?.answer_en}
                                                    </p>
                                                </motion.div>
                                            )}
                                        </AnimatePresence>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </>
                    : <>
                        <p className="px-grid-lg md:px-0 md:container mx-auto text-xl font-primary text-primary-lightest">
                            {prefs?.lang === constant.lang.values[0] ? page?.page_title_ar : page?.page_title_en}
                        </p>
                        <div className="mx-grid-lg md:mx-auto md:container mt-grid-xl mb-[100px] p-grid-sm lg:p-grid-lg bg-primary-lightest text-secondary-darkest text-sm font-secondary rounded-sm shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)]">
                            <p>
                                {prefs?.lang === constant.lang.values[0] ? page?.page_content_ar : page?.page_content_en}
                            </p>
                            <p className="font-primary text-sm text-end pt-grid-sm lg:pt-grid-lg">
                                {`${strings("common.lastUpdate")} ${getDate(prefs?.lang, getDate(prefs?.lang, page?.last_updated_at, "YYYY-MM-DD"))}`}
                            </p>
                        </div>
                    </>
                }
            </div>
        </Section>
    );
};

export default StaticPage;