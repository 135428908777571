import React, { useContext } from "react";

import { Trans } from "react-i18next";

import InfoCard from "./infoCard";

import { strings } from "../../util/formatter";
import { SessionContext } from "../../util/context";

import landingPageShape8 from "../../assets/image/landing_page_shape_8.webp";
import landingPageShape1 from "../../assets/image/landing_page_shape_1.webp";
import landingPageShape2 from "../../assets/image/landing_page_shape_2.webp";

const Section10 = () =>
{
    const { setUserPersona, setActiveModal, modalOptions, setModalOptions } = useContext(SessionContext);
    const cards = [
        {
            iconName: "pieChart",
            title: strings("landingPage.tenthSectionFirstCardTitle"),
            description: strings("landingPage.tenthSectionFirstCardDescription"),
            withShadow: false
        },
        {
            iconName: "share",
            title: strings("landingPage.tenthSectionSecondCardTitle"),
            description: strings("landingPage.tenthSectionSecondCardDescription"),
            withShadow: false
        },
        {
            iconName: "lock",
            title: strings("landingPage.tenthSectionThirdCardTitle"),
            description: strings("landingPage.tenthSectionThirdCardDescription"),
            withShadow: false
        },
        {
            iconName: "pieChart",
            title: strings("landingPage.tenthSectionForthCardTitle"),
            description: strings("landingPage.tenthSectionForthCardDescription"),
            withShadow: false
        },
        {
            iconName: "share",
            title: strings("landingPage.tenthSectionFifthCardTitle"),
            description: strings("landingPage.tenthSectionFifthCardDescription"),
            withShadow: false
        },
        {
            iconName: "lock",
            title: strings("landingPage.tenthSectionSixthCardTitle"),
            description: strings("landingPage.tenthSectionSixthCardDescription"),
            withShadow: false
        },
        {
            iconName: "share",
            title: strings("landingPage.tenthSectionSecondCardTitle"),
            description: strings("landingPage.tenthSectionSecondCardDescription"),
            withShadow: false
        },
        {
            iconName: "lock",
            title: strings("landingPage.tenthSectionThirdCardTitle"),
            description: strings("landingPage.tenthSectionThirdCardDescription"),
            withShadow: false
        }
    ];

    return (
        <div className="xl:flex xl:justify-between pb-grid-xxl">
            <div className="h-auto relative min-w-full max-w-full xl:min-w-[24%] xl:max-w-[24%]">
                <div className="top-[35%] lg:sticky">
                    <div className="px-grid-md md:px-0 md:pe-grid-sm text-xl font-primary text-secondary-darkest text-center md:text-start">
                        <Trans
                            i18nKey="landingPage.tenthSectionHead"
                            components={{ span: <span className="text-secondary-lightest" />, p: <p /> }}
                        />
                    </div>
                    <img src={landingPageShape8} className="w-[49%] z-10 mx-auto blur-[2px]"/>
                </div>
            </div>
            <div className="hidden md:flex w-full gap-grid-lg justify-center xl:justify-end xl:overflow-y-hidden pe-10 pb-16">
                <div className="flex flex-col gap-grid-lg">
                    {cards.map((item, index) => (
                        index % 2 === 0 &&
                            <InfoCard
                                key={index}
                                item={item}
                                shouldShowButton={false}
                                // buttonText={strings("button.joinAsDistributor")}
                                // buttonAction={() =>
                                // {
                                //     setActiveModal("signup");
                                //     setUserPersona("distributor");
                                //     setModalOptions({
                                //         ...modalOptions,
                                //         title: strings("title.signup")
                                //     });
                                // }}
                            />
                    ))}
                </div>
                <div className="relative pt-[370px] flex flex-col gap-grid-lg">
                    <img src={landingPageShape1} className="absolute top-[250px] z-0 start-grid-xl"/>
                    <img src={landingPageShape2} className="absolute top-grid-xl z-0 end-grid-xl"/>
                    {cards.map((item, index) => (
                        index % 2 > 0 &&
                            <InfoCard
                                key={index}
                                item={item}
                                shouldShowButton={false}
                                // buttonText={strings("button.joinAsDistributor")}
                                // buttonAction={() =>
                                // {
                                //     setActiveModal("signup");
                                //     setUserPersona("distributor");
                                //     setModalOptions({
                                //         ...modalOptions,
                                //         title: strings("title.signup")
                                //     });
                                // }}
                            />
                    ))}
                </div>
            </div>
            <div className="flex md:hidden w-full gap-grid-lg justify-center overflow-y-hidden pb-10">
                <div className="flex flex-col gap-grid-lg">
                    {cards.map((item, index) => (
                        <InfoCard
                            key={index}
                            item={item}
                            shouldShowButton={false}
                            // buttonText={strings("button.joinAsDistributor")}
                            // buttonAction={() =>
                            // {
                            //     setActiveModal("signup");
                            //     setUserPersona("distributor");
                            //     setModalOptions({
                            //         ...modalOptions,
                            //         title: strings("title.signup")
                            //     });
                            // }}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Section10;