import React, { useContext, useState } from "react";

import Label from "../view/component/label";
import Input from "../view/component/input";
import LineMsg from "../view/component/lineMsg";
import DropdownMenu from "../view/component/dropdownMenu";
import SVGIcon from "../view/component/svgIcons";

import { strings } from "../util/formatter";
import { isNotEmpty, validateEmail } from "../util/validator";
import { request } from "../util/http";
import { SessionContext } from "../util/context";
import endpoint from "../config/endpoint";
import constant from "../config/constant";
import { showNotification } from "../util/notification";

const InviteEntityUsers = () =>
{
    const { prefs } = useContext(SessionContext);
    const [email, setEmail] = useState("");
    const [role, setRole] = useState(constant.user_role[1]);
    const [isLoading, setIsLoading] = useState(false);
    const isValidForm = isNotEmpty(email) && validateEmail(email)?.isValid && role?.value

    const invite = async () =>
    {
        if (isValidForm && !isLoading)
        {
            try
            {
                const data = {
                    email,
                    role_code: role?.value
                };

                const res = await request("POST", endpoint.crm.user.invitation, data);
                setEmail("");
                setRole(constant.user_role[1]);
                showNotification("success", strings("notification_message.inviteUser"), strings("notification_message.successInviteUser"), prefs?.dir);
                setIsLoading(false);
            }
            catch (error)
            {
                showNotification("error", strings("notification_message.inviteUser"), error?.error_msg, prefs?.dir);
                setIsLoading(false);
            }
        }
    };

    return (
        <div className="p-grid-md text-neutral-low-darkest font-secondary">
            <div className="w-full p-grid-xs">
                <Label label={strings("label.enterEmailToAddUser")}/>
                <div className="flex justify-between items-center gap-grid-sm">
                    <div className="w-full">
                        <Input
                            name="email"
                            placeholder={strings("placeholder.email")}
                            value={email}
                            setValue={setEmail}
                        />
                    </div>
                    <DropdownMenu
                        list={constant.user_role?.filter((item) => item?.value !== "owner")}
                        labelKey={prefs?.lang === constant.lang.values[0] ? "label_ar" : "label_en"}
                        selectedValue={role}
                        setSelected={setRole}
                        width="w-[130px] sm:w-[160px]"
                        gap="gap-grid-xs"
                    />
                    <div onClick={invite} className={`${isValidForm && !isLoading ? "cursor-pointer hover:opacity-70" : "opacity-50 cursor-not-allowed"} transition-opacity duration-fast`}>
                        <SVGIcon name="addInCircle" width={24} height={24} viewBox="0 0 44 44" className={"fill-neutral-low-darkest"}/>
                    </div>
                </div>
                <LineMsg msg={validateEmail(email)?.msg} type="error"/>
            </div>
        </div>
    );
};

export default InviteEntityUsers;