import React, { useContext, useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import Section from "../view/component/section";
import Loader from "../view/component/loader";
import DropdownMenu from "../view/component/dropdownMenu";
import Button from "../view/component/button";
import ChartContestantSmall from "../view/component/chartContestantSmall";

import { SessionContext } from "../util/context";
import { getDate, getDateISO, getLangCountryURL, strings } from "../util/formatter";
import { request } from "../util/http";
import constant from "../config/constant";
import endpoint from "../config/endpoint";

const Catalog = () =>
{
    const { prefs } = useContext(SessionContext);
    const params = useParams();
    const navigate = useNavigate();
    const [shouldLoadMore, setShouldLoadMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [productAtt, setProductAtt] = useState([]);
    const [filterType, setFilterType] = useState({});
    const [filterValue, setFilterValue] = useState({});
    const [productList, setProductList] = useState([]);
    const [pageNum, setPageNum] = useState(1);

    const loadMore = () =>
    {
        if (shouldLoadMore)
        {
            setPageNum(pageNum + 1);
        }
    };

    const CATEGORY = [
        {
            title: "common.allBooks",
            key: "all"
        },
        {
            title: "common.category",
            key: "subject"
        }
    ];

    const fetchProductAtt = async () =>
    {
        setIsLoading(true);
        try
        {
            const res = await request("GET", `${endpoint.bms.product.attribute}?attribute_code=subject`);
            setProductAtt(res?.list);
            if (params?.filterValue)
            {
                setFilterValue(res?.list?.filter(item => item?.value === params?.filterValue)?.length > 0 ? res?.list?.filter(item => item?.value === params?.filterValue)?.[0] : {});
                setTimeout(() =>
                {
                    fetchProductList();
                }, 100);
            }
            setIsLoading(false);
        }
        catch (error) {
            setIsLoading(false);
        }
    };

    const fetchProductList = async (isLoadMore = false) =>
    {
        setIsLoading(true);
        try
        {
            const res = await request("GET", `${endpoint.bms.product.list}?page=${pageNum}&timestamp=${getDateISO()}${params?.filterType === "all" ? "" : !filterValue?.value ? (params?.filterValue ? `&subject_code=${params?.filterValue}` : "") : `&subject_code=${filterValue?.value}`}`);
            setProductList(isLoadMore ? productList?.concat(res?.list) : res?.list);
            if (res?.list?.length < 6) setShouldLoadMore(false);
            setIsLoading(false);
        }
        catch (error) {
            console.log(error)
            setIsLoading(false);
        }
    };

    const onClickFilterList = () =>
    {
        navigate(`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/catalog/${filterType.key ? filterType.key : "all"}${filterValue?.value ? `/${filterValue?.value}` : ""}`);
    };

    const setParamsIntoState = async () =>
    {
        setFilterType(CATEGORY?.filter(item => item.key === params?.filterType)?.length > 0 ? CATEGORY?.filter(item => item.key === params?.filterType)?.[0] : {});
        fetchProductAtt();
        if (!params?.filterValue) fetchProductList();
    };

    useEffect(() =>
    {
        setParamsIntoState();
    }, [params?.filterType, params?.filterValue]);

    useEffect(() =>
    {
        if (pageNum !== 1)
        {
            fetchProductList(true);
        }
    }, [pageNum]);

    useEffect(() =>
    {
        if (Object?.keys(filterType)?.length !== 0)
        {
            setFilterValue({});
            setPageNum(1);
            setShouldLoadMore(true);
        }
    }, [filterType]);

    return (
        <Section optionalStyle="min-h-[calc(100vh-474px)]">
            {isLoading && <Loader />}
            <div className="relative pt-[60%] sm:pt-[40%] md:pt-[35%] lg:pt-[20%]">
                <div className="flex items-center justify-between gap-grid-xs pb-grid-xl text-primary-lightest">
                    <div>
                        <p className="text-xl font-primary text-primary-lightest">
                            {strings(`common.booksDirectory`)}
                        </p>
                    </div>
                    <div className="flex items-center gap-grid-xs">
                        <DropdownMenu
                            useLocalization={true}
                            list={CATEGORY}
                            labelKey="title"
                            placeholder={strings("placeholder.filterType")}
                            selectedValue={filterType}
                            setSelected={setFilterType}
                            dropdownBG="bg-secondary-lightest"
                        />
                        <DropdownMenu
                            useLocalization={true}
                            list={filterType?.key === "all" ? [] : productAtt}
                            labelKey={prefs?.lang === constant.lang.values[0] ? "name_ar" : "name_en"}
                            placeholder={strings("placeholder.filterValue")}
                            selectedValue={filterValue}
                            setSelected={setFilterValue}
                            dropdownBG="bg-secondary-lightest"
                        />
                        <Button
                            text={strings("button.filterList")}
                            bgColor={"bg-accent-regular"}
                            hoverBGColor={"hover:bg-transparent"}
                            textColor={"text-accent-lightest"}
                            hoverTextColor={"hover:text-accent-regular"}
                            border={"border-2 border-transparent"}
                            hoverBorder={"hover:border-2 hover:border-accent-regular"}
                            padding="py-2 px-4"
                            onClick={onClickFilterList}
                        />
                    </div>
                </div>
                <div className="mx-grid-lg md:mx-auto md:container mb-grid-xxl">
                    {productList?.length > 0
                        ? <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-grid-xl sm:gap-grid-sm md:gap-grid-xl py-grid-lg justify-items-center md:justify-start lg:justify-center">
                            {productList?.map((product, index) => (
                                <ChartContestantSmall
                                    key={index}
                                    item={product}
                                    containerClassName="min-h-[400px] max-w-[280px] max-h-[400px] p-grid-lg flex flex-col justify-between items-center bg-primary-lightest rounded-sm cursor-pointer shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)]"
                                    imgClassName="w-[150px] h-[210px]"
                                    textClassName="pt-grid-xs text-center"
                                    imgKey="thumbnail_url"
                                    titleKey={prefs?.lang === constant.lang.values[0] ? "name_ar" : "name_en"}
                                />
                            ))}
                        </div>
                        : <div>
                            <p className="mt-grid-xl mb-[180px] text-primary-lightest text-center">{strings("empty.newArrival")}</p>
                        </div>
                    }
                </div>
                {shouldLoadMore &&
                (
                    <div className="text-center my-grid-xxl">
                        <Button
                            text={strings("common.loadMore")}
                            bgColor={"bg-transparent"}
                            hoverBGColor={"hover:bg-accent-lightest"}
                            textColor={"text-accent-lightest"}
                            hoverTextColor={"hover:text-primary-regular"}
                            border={"border-2 border-accent-lightest"}
                            hoverBorder={"hover:border-2 hover:border-transparent"}
                            onClick={loadMore}
                        />
                    </div>
                )}
            </div>
        </Section>
    );
};

export default Catalog;