import React, { useContext, useRef } from "react";

import DateRange from "./dateRange";
import ShowAll from "./showAll";
import ChartContestantSmall from "./chartContestantSmall";

import { SessionContext } from "../../util/context";
import { getDate, getLangCountryURL, strings } from "../../util/formatter";
import useMediaQuery from "../../hook/useMediaQuery";

const Section4 = ({ contributorsChartState }) =>
{
    const { prefs } = useContext(SessionContext);
    const targetRef = useRef(null);
    const isSmall = useMediaQuery("(min-width: 640px) and (max-width: 767px)");
    const isMedium = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");

    return (
        <div ref={targetRef} className={`relative mb-grid-xxl mx-grid-lg md:mx-0 flex flex-col justify-start`}>
            <p className="pb-grid-sm text-xl font-primary text-primary-lightest">
                {strings("landingPage.fourthSectionHead")}
            </p>
            <div className="flex justify-between items-start">
                <DateRange dateFrom={getDate(prefs?.lang, contributorsChartState?.start_date, "YYYY-MM-DD")} dateTo={getDate(prefs?.lang, contributorsChartState?.end_date, "YYYY-MM-DD")} />
                <ShowAll route={`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/charts/contributor`}/>
            </div>
            <div className="mb-[10%] w-full bg-primary-lightest shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)] rounded-md">
                <div className="md:mx-0 grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-grid-lg p-grid-lg justify-items-center md:justify-start lg:justify-center">
                    {contributorsChartState?.list?.slice(0, isMedium ? 4 : isSmall ? 3 : 5)?.sort((a, b) => a.position_current - b.position_current)?.map((item, index) =>
                        (
                            <ChartContestantSmall
                                key={index}
                                item={item}
                                containerClassName="min-w-[176px] max-w-[176px] h-[243px] flex flex-col justify-between bg-primary-light text-secondary-darkest p-grid-md cursor-pointer rounded-sm"
                                roundedImg={true}
                                shouldShowRank={true}
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default Section4;