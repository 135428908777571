import React, { useContext } from "react";

import { SessionContext } from "../../util/context";
import constant from "../../config/constant";
import LoadingSkeleton from "./loadingSkeleton";

const ManageBankAccountsList = (props) =>
{
    const { prefs, isPostLogin } = useContext(SessionContext)
    const { isLoading, accountList, bankList } = props;

    return (
        <div className={`p-grid-sm ${isPostLogin ? "text-neutral-low-darkest divide-neutral-low-darkest/20" : "text-secondary-darkest divide-primary-light"} divide-y divide-solid`}>
            {isLoading
            ? <LoadingSkeleton rows="4" rowHeight="h-[21px]" rowWidth = "w-full"/>
            : accountList?.map((item, index) => (
                <div key={index} className={`p-grid-sm ${index === accountList?.length - 1 && "pb-grid-md"} flex gap-grid-sm items-center justify-between text-sm`}>
                    <div className="flex gap-grid-xs items-center">
                        {bankList?.filter(bank => bank?.bank_code === item?.bank_code)?.[0]?.name_ar
                        ? (prefs?.lang === constant.lang.values[0]
                            ? bankList?.filter(bank => bank?.bank_code === item?.bank_code)?.[0]?.name_ar
                            : bankList?.filter(bank => bank?.bank_code === item?.bank_code)?.[0]?.name_en
                        )
                        : item?.holder_name}
                    </div>
                    <div className="flex items-center">
                        {item?.bank_account_number}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ManageBankAccountsList;