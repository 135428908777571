import React, { useContext } from "react";

import Label from "./label";
import DropdownMenu from "./dropdownMenu";
import Input from "./input";
import LineMsg from "./lineMsg";

import { SessionContext } from "../../util/context";
import { strings } from "../../util/formatter";
import constant from "../../config/constant";
import { validateLettersWithSpaces, validateNumbersOnly } from "../../util/validator";

const ManageBankAccountsCreate = (props) =>
{
    const { prefs } = useContext(SessionContext)
    const { bankList, accountForm, setAccountForm } = props;
    const {
        selectedBank,
        accountNumber,
        IBANNumber,
        holderName
    } = accountForm || {};

    const onValueChange = (value, key) =>
    {
        setAccountForm({ ...accountForm, [key]: value });
    };

    return (
        <>
            <div className="w-1/2 pb-grid-sm">
                <Label label={strings("label.bankName")}/>
                <DropdownMenu
                    list={bankList}
                    labelKey={prefs?.lang === constant.lang.values[0] ? "name_ar" : "name_en"}
                    placeholder={strings("placeholder.selectBankName")}
                    selectedValue={selectedBank}
                    setSelected={(e) => { onValueChange(e, "selectedBank") }}
                />
                <LineMsg type="error"/>
            </div>
            <div className="flex flex-col sm:flex-row justify-between sm:gap-grid-xl">
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.accountNumber")}/>
                    <Input
                        name="accountNumber"
                        value={accountNumber}
                        setValue={(e) => { onValueChange(e, "accountNumber") }}
                    />
                    <LineMsg msg={validateNumbersOnly(accountNumber)?.msg} type="error"/>
                </div>
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.IBANNumber")}/>
                    <Input
                        name="IBANNumber"
                        value={IBANNumber}
                        setValue={(e) => { onValueChange(e, "IBANNumber") }}
                    />
                    <LineMsg msg={validateNumbersOnly(IBANNumber)?.msg} type="error"/>
                </div>
            </div>
            <div className="pb-grid-sm">
                <Label label={strings("label.holderName")}/>
                <Input
                    name="holderName"
                    value={holderName}
                    setValue={(e) => { onValueChange(e, "holderName") }}
                />
                <LineMsg msg={validateLettersWithSpaces(holderName, "en")?.msg} type="error"/>
            </div>
        </>
    );
};

export default ManageBankAccountsCreate;