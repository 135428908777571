import constant from "../config/constant";

import { Store } from "react-notifications-component";

export const showNotification = (type, title, message, dir) =>
{
    if (["success", "error", "info"].indexOf(type?.toLowerCase()) >= 0)
    {
        type = type?.toLowerCase() === "error" ? "danger" : type?.toLowerCase();
    }
    else
    {
        type = "warning";
    }

    Store.addNotification(
        {
            title,
            message,
            type,
            insert: "top",
            container: dir === "ltr" ? "top-right" : "top-left",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss:
            {
                duration: constant?.animation?.speed.normal,
                onScreen: true
            }
        });
};