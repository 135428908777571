import React, { useContext, useState } from "react";

import Label from "./label";
import LineMsg from "./lineMsg";

import { strings, truncateString } from "../../util/formatter";
import SVGIcon from "./svgIcons";
import { SessionContext } from "../../util/context";

const ManageEntityIntegration = () =>
{
    const { merchantInfo } = useContext(SessionContext);
    const [isCopied, setIsCopied] = useState(false);

    const onCopyClick = () =>
    {
        if (!isCopied)
        {
            setIsCopied(true);
            navigator.clipboard.writeText(merchantInfo?.api_key);
            setTimeout(() =>
            {
                setIsCopied(false);
            }, 2000);
        }
    };

    return (
        <>
            <p className="text-sm mb-grid-sm">
                {strings("common.keyProtectionMsg")}
            </p>
            <div className="h-[1px] bg-neutral-high-regular mt-grid-md mb-grid-xs"/>
            <div className="w-full py-grid-sm">
                <Label label={strings("label.yourEntityAPIKey")}/>
                <div className="flex justify-between p-grid-xs bg-neutral-high-regular rounded-xs">
                    <p dir="ltr" className="text-md">{truncateString(merchantInfo?.api_key, 50)}</p>
                    <div onClick={onCopyClick} className="cursor-pointer">
                        {isCopied

                            ? <p className="text-sm">{strings("common.copied")}</p>
                            : <SVGIcon name="copy" width={20} height={20} viewBox="0 0 24 24" className="fill-neutral-low-dark"/>
                        }

                    </div>
                </div>
                <LineMsg type="error"/>
            </div>
        </>
    );
};

export default ManageEntityIntegration;