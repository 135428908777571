import React, { useContext, useEffect, useState } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import CachedImg from "./cachedImg";
import SVGIcon from "./svgIcons";

import { SessionContext } from "../../util/context";
import { getLangCountryURL, strings } from "../../util/formatter";
import constant from "../../config/constant";

import LOGO_LIGHT from "../../assets/image/logo_light.png";
import LOGO_DARK from "../../assets/image/logo_dark.png";

const SubMenu = (props) =>
{
    const { prefs, shouldShowBnr, setAnimateOpacity } = useContext(SessionContext);
    const location = useLocation();
    const { isResponsive = false, toggleMenu = () => {} } = props;
    const [activeMain, setActiveMain] = useState([location.pathname?.includes("dashboard/trend") ? 1 : location.pathname?.includes("dashboard/producer") ? 2 : location.pathname?.includes("dashboard/distributor") ? 3 : -1]);
    const [active, setActive] = useState(location.pathname);
    const navigate = useNavigate();

    const delayAndGo = (e, path) =>
    {
        e.preventDefault();
        setTimeout(() => navigate(path), 250);
        setAnimateOpacity(true);
        setTimeout(() => setAnimateOpacity(false), 500);
    }

    useEffect(() =>
    {
        setActive(location.pathname);
    }, [location.pathname]);

    const handleActiveMain = (index) =>
    {
        if (activeMain?.includes(index))
        {
            setActiveMain(activeMain?.filter(item => item !== index));
        }
        else
        {
            setActiveMain([...activeMain, index])
        }
    }

    return (
        <motion.div
            initial={isResponsive && { translateX: 375 }}
            animate={isResponsive && { translateX: 0 }}
            exit={isResponsive && { translateX: 375 }}
            transition={isResponsive && { duration: 0.5 }}
            data-lenis-prevent
            className={`${isResponsive ? `fixed block lg:hidden z-10 top-0 start-0 h-screen` : "relative hidden lg:block"} ${shouldShowBnr ? "h-[calc(100vh-72px)]" : "h-[calc(100vh-32px)]"} max-w-[270px] min-w-[270px] xl:max-w-[300px] xl:min-w-[300px] transition-colors duration-fast ease-in bg-neutral-high-regular text-neutral-low-regular overflow-y-scroll overflow-x-hidden ${!isResponsive && "rounded-s-md"}`}
        >
            <div className="h-[130px] max-w-[270px] min-w-[270px] xl:max-w-[300px] xl:min-w-[300px] absolute z-0 top-32 -end-2/3 rounded-full bg-gradient-to-r from-primary-regular to-secondary-regular blur-[70px]"/>
            <div className="flex justify-between items-start">
                <Link to={`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}`}>
                    <CachedImg src={prefs?.theme === constant?.theme?.values?.[0] ? LOGO_DARK : LOGO_LIGHT} alt={strings("mofahres")} className="relative max-w-[120px] pt-grid-lg pb-grid-lg mx-grid-md z-10"/>
                </Link>
                {isResponsive && (
                    <div className="p-grid-sm">
                        <div className="cursor-pointer hover:opacity-70 transition-opacity duration-fast" onClick={toggleMenu}>
                            <SVGIcon name={"close"} stroke="stroke-neutral-low-darkest" width={30} height={30} />
                        </div>
                    </div>
                )}
            </div>
            {constant.menu_section.map((section, index) =>
            {
                return (
                    <div key={section.key} className="relative text-sm z-10">
                        {section?.items?.length > 0
                        ? <div
                                className={`flex justify-between items-center gap-grid-xs mb-grid-xs mx-grid-sm p-3 ${section?.items?.filter((item) => active?.includes(item?.key))?.length > 0 ? "bg-primary-regular text-primary-lightest" : "bg-transparent text-neutral-low-darkest"} rounded-xs cursor-pointer transition-all duration-too-fast ease-in hover:opacity-60`}
                                onClick={() => handleActiveMain(index)}
                            >
                                <div className="flex items-center gap-grid-sm">
                                    <SVGIcon name={section.icon} width={16} height={16} viewBox={"0 0 24 24"} stroke={`${section?.items?.filter((item) => active?.includes(item?.key))?.length > 0 ? "stroke-primary-lightest" : "stroke-neutral-low-darkest"} transition-colors duration-too-fast ease-in`}/>
                                    {strings(section.title)}
                                </div>
                                <div>
                                    <SVGIcon name="chevron" width={16} height={16} viewBox="0 0 24 24" stroke={`${section?.items?.filter((item) => active?.includes(item?.key))?.length > 0 ? "stroke-primary-lightest" : "stroke-neutral-low-darkest"} transition-colors duration-too-fast ease-in`} className={`${activeMain?.includes(index) ? "-rotate-90" : "rotate-90"} fill-none`}/>
                                </div>
                            </div>
                        : <Link
                                className={`flex justify-between items-center gap-grid-xs mb-grid-xs mx-grid-sm p-3 ${location.pathname?.includes(section.path) ? "bg-primary-regular text-primary-lightest" : "bg-transparent text-neutral-low-darkest"} rounded-xs cursor-pointer transition-all duration-too-fast ease-in hover:opacity-60`}
                                to={`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard/${section.path}`}
                                onClick={(e) => delayAndGo(e, `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard/${section.path}`)}
                            >
                                <div className="flex items-center gap-grid-sm">
                                    <SVGIcon name={section.icon} width={16} height={16} viewBox={"0 0 24 24"} stroke={`${location.pathname?.includes(section.path) ? "stroke-primary-lightest" : "stroke-neutral-low-darkest"} transition-colors duration-too-fast ease-in`} className={`fill-none`}/>
                                    {strings(section.title)}
                                </div>
                            </Link>
                        }
                        {section?.items?.length > 0 &&
                            <AnimatePresence>
                                {activeMain?.includes(index) &&
                                    <motion.div
                                        style={{ overflow: "hidden" }}
                                        initial={{ height: 0, paddingBottom: 0 }}
                                        animate={{ height: "auto", paddingBottom: "0.5rem" }}
                                        exit={{ height: 0, paddingBottom: 0 }}
                                        transition={{ duration: 0.5 }}
                                    >
                                        {section?.items?.map((subSection, index) =>
                                        {
                                            return (
                                                <Link
                                                    key={subSection.key}
                                                    to={`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard/${subSection.path}`}
                                                    onClick={(e) => delayAndGo(e, `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard/${subSection.path}`)}
                                                    className={`flex items-center gap-grid-xs ps-5 mx-grid-sm ${index !== section?.items?.length - 1 && "mb-grid-xxs"} ${!active?.includes(subSection.key) && "transition-opacity duration-fast hover:opacity-60"}`}
                                                >
                                                    <div className={`w-[10px] h-[10px] flex justify-center items-center border-[3px] border-primary-regular ${active?.includes(subSection.key) ? "bg-primary-regular" : "bg-primary-lightest"} rounded-full`}/>
                                                    <div className={`w-full p-3 ${active?.includes(subSection.key) ? "bg-primary-lightest text-secondary-darkest shadow-[0_5px_5px_0_rgba(0,0,0,0.1)]" : "bg-transparent text-neutral-low-darkest"} rounded-xs transition-colors duration-too-fast ease-in`}>
                                                        {strings(subSection.title)}
                                                    </div>
                                                </Link>
                                            );
                                        })}
                                    </motion.div>
                                }
                            </AnimatePresence>
                        }
                    </div>
                );
            })}
        </motion.div>
    );
};

export default SubMenu;