import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import Label from "../view/component/label";
import Input from "../view/component/input";
import LineMsg from "../view/component/lineMsg";
import Button from "../view/component/button";
import PhoneNumberInput from "../view/component/phoneNumberInput";

import { SessionContext } from "../util/context";
import { getLangCountryURL, strings } from "../util/formatter";
import { request } from "../util/http";
import { isNotEmpty, validateEmail, validateLettersOnly, validatePassword, validatePhoneNumber, validateSameLanguage } from "../util/validator";
import endpoint from "../config/endpoint";

const Signup = () =>
{
    const { prefs, setActiveModal, setter } = useContext(SessionContext);
    const navigate = useNavigate();
    const [countryList, setCountryList] = useState([]);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [countryCode, setCountryCode] = useState(
        {
            country_code: "sa",
            mobile_code: "966",
            mobile_example: "512345678",
            name_ar: "السعودية",
            name_en: "Saudi Arabia",
            is_default: true,
            is_served: true,
            mobile_prefix: "5",
            mobile_skipped_digit: "0"
        }
    );
    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const [createAccountErrorMsg, setCreateAccountErrorMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const isValidForm =
    isNotEmpty(firstName) && isNotEmpty(lastName) && isNotEmpty(email) &&
    isNotEmpty(countryCode?.mobile_code) && isNotEmpty(phoneNumber) && isNotEmpty(password) &&
    validateSameLanguage(firstName, lastName)?.isValid &&
    validateLettersOnly(firstName)?.isValid &&
    validateLettersOnly(lastName)?.isValid &&
    validateEmail(email)?.isValid &&
    validatePhoneNumber(phoneNumber, countryCode?.mobile_code)?.isValid &&
    validatePassword(password)?.isValid;

    const onCreateAccount = async () =>
    {
        if (isValidForm)
        {
            setCreateAccountErrorMsg("");
            setIsLoading(true);
            try
            {
                const postData =
                {
                    email,
                    mobile: phoneNumber,
                    mobile_code : countryCode?.mobile_code,
                    password
                };
                const putData =
                {
                    first_name: firstName,
                    last_name: lastName
                };

                const postRes = await request("POST", endpoint.crm.user.data, postData);
                const userInfo = { ...postRes?.info?.userInfo, ...putData }

                setter("access_token", postRes?.info?.access_token);
                setter("refresh_token", postRes?.info?.refresh_token);
                setter("user_info", JSON.stringify(userInfo));

                request("PUT", endpoint.crm.user.data, putData);

                setActiveModal(undefined);
                navigate(`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/directory`);
            }
            catch (error) {
                setCreateAccountErrorMsg(error?.error_msg);
                setIsLoading(false);
            }
        }
    };

    const fetchCountryList = async () =>
    {
        try
        {
            const res = await request("GET", endpoint.cms.country.list);
            setCountryList(res?.list);
        }
        catch (error) {}
    };

    useEffect(() =>
    {
        fetchCountryList();
    }, []);

    return (
        <>
            <div className="flex justify-between gap-4">
                <div className="w-full pb-4">
                    <Label label={strings("label.firstName")}/>
                    <Input
                        name="firstName"
                        value={firstName}
                        placeholder={strings("placeholder.firstName")}
                        setValue={setFirstName}
                    />
                    <LineMsg msg={validateSameLanguage(firstName, lastName)?.isValid ? validateLettersOnly(firstName)?.msg : validateSameLanguage(firstName, lastName)?.msg} type="error"/>
                </div>
                <div className="w-full pb-4">
                    <Label label={strings("label.lastName")}/>
                    <Input
                        name="lastName"
                        value={lastName}
                        placeholder={strings("placeholder.lastName")}
                        setValue={setLastName}
                    />
                    <LineMsg msg={validateLettersOnly(lastName)?.msg} type="error"/>
                </div>
            </div>
            <div className="pb-4">
                <Label label={strings("label.email")}/>
                <Input
                    name="email"
                    value={email}
                    placeholder={strings("placeholder.email")}
                    setValue={setEmail}
                />
                <LineMsg msg={validateEmail(email)?.msg} type="error"/>
            </div>
            <div className="pb-4">
                <Label label={strings("label.phoneNumber")}/>
                <PhoneNumberInput
                    countryList={countryList}
                    phoneNumber={phoneNumber}
                    countryCode={countryCode}
                    setPhoneNumber={setPhoneNumber}
                    setCountryCode={setCountryCode}
                />
                <LineMsg msg={validatePhoneNumber(phoneNumber, countryCode?.mobile_code)?.msg} type="error"/>
            </div>
            <div className="pb-4">
                <Label label={strings("label.password")}/>
                <Input
                    name="password"
                    value={password}
                    type="password"
                    placeholder={strings("placeholder.password")}
                    setValue={setPassword}
                />
                <LineMsg msg={validatePassword(password)?.msg} type="error"/>
            </div>
            <div className={`flex justify-between pt-grid-lg`}>
                <LineMsg msg={createAccountErrorMsg} type="error"/>
                <Button
                    disabled={!isValidForm || isLoading}
                    text={strings("button.create")}
                    padding = "py-2 px-10"
                    onClick={onCreateAccount}
                />
            </div>
        </>
    );
};

export default Signup;