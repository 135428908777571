import React, { useContext } from "react";

import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import CachedImg from "./cachedImg";
import Rank from "./rank";

import { SessionContext } from "../../util/context";
import { getLangCountryURL } from "../../util/formatter";
import Avatar from "./avatar";

const ChartContestantSmall = (props) =>
{
    const { prefs } = useContext(SessionContext);
    const {
        item,
        containerClassName = "w-[190px] h-[270px] bg-primary-light text-secondary-darkest p-grid-md cursor-pointer rounded-md",
        imgClassName = "w-[70px] h-[115px] mx-auto bg-primary-light",
        textClassName = "text-base text-center pt-grid-xs",
        secondTextClassName = "text-xs text-center pt-grid-xs",
        shouldScale = true,
        roundedImg = false,
        size = "w-[96px] h-[96px]",
        isLastItem,
        lastItemRef,
        shouldShowRank = false,
        imgKey = undefined,
        titleKey = undefined,
        secondaryTitleKey = undefined
    } = props;
    return (
        <Link to={`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/product/${item.id}`}>
            <motion.div ref={isLastItem ? lastItemRef : null} whileHover={{ scale: shouldScale ? 1.05 : 1 }} className={containerClassName}>
                <div>
                    {roundedImg
                        ? <Avatar src={item?.[imgKey] ? item?.[imgKey] : (item?.thumbnail || item?.image)} size={size}/>
                        : <CachedImg src={item?.[imgKey] ? item?.[imgKey] : item?.thumbnail || item?.image} className={imgClassName}/>
                    }
                    {shouldShowRank &&
                        <div className="py-2"><Rank rank={item?.position_current} shouldShowRankText={true} period={item?.period_in_chart} rankPrevious={item?.position_previous} /></div>
                    }
                </div>

                <p className={textClassName}>
                    {item?.[titleKey] ? item?.[titleKey] : item?.title_main}
                </p>
                {item?.title_secondary &&
                    <p className={secondTextClassName}>
                        {item?.[secondaryTitleKey] ? item?.[secondaryTitleKey] : item?.title_secondary}
                    </p>
                }
            </motion.div>
        </Link>
    );
};

export default ChartContestantSmall;