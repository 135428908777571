import React, { useContext, useState } from "react";

import Label from "../view/component/label";
import Input from "../view/component/input";
import LineMsg from "../view/component/lineMsg";
import Button from "../view/component/button";

import { strings } from "../util/formatter";
import OTP from "./otp";
import { SessionContext } from "../util/context";

const ResetPassword = () =>
{
    const { setActiveModal, modalOptions, setModalOptions } = useContext(SessionContext);
    const [currentStep, setCurrentStep] = useState(modalOptions?.currentStep ? 2 : 1);
    const [email, setEmail] = useState(modalOptions?.email ? modalOptions?.email : "");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");

    const onClickNext = () =>
    {
        if (currentStep === 1)
        {
            setModalOptions({ title: strings("title.changePassword"), onVerify: onClickVerfiy });
            setCurrentStep(currentStep => currentStep + 1);
        }
        else if (currentStep === 3)
        {
            setActiveModal(undefined);
        }
    };

    const onClickVerfiy = () =>
    {
        setCurrentStep(currentStep => currentStep + 1);
    };

    return (
        <>
            {currentStep === 1
                ? <>
                    <div className="pb-4">
                        <Label label={strings("label.email")}/>
                        <Input
                            name="email"
                            value={email}
                            placeholder={strings("placeholder.email")}
                            setValue={setEmail}
                        />
                        <LineMsg type="error"/>
                    </div>
                </>
                : currentStep === 2
                    ? <OTP
                        isModal={false}
                    />
                    : <>
                        <div className="pb-4">
                            <Label label={strings("label.newPassword")}/>
                            <Input
                                name="newPassword"
                                value={newPassword}
                                type="password"
                                placeholder={strings("placeholder.password")}
                                setValue={setNewPassword}
                            />
                            <LineMsg type="error"/>
                        </div>
                        <div className="pb-4">
                            <Label label={strings("label.confirmNewPassword")}/>
                            <Input
                                name="confirmPassword"
                                value={confirmNewPassword}
                                type="password"
                                placeholder={strings("placeholder.password")}
                                setValue={setConfirmNewPassword}
                            />
                            <LineMsg type="error"/>
                        </div>
                    </>
            }
            {[1, 3].includes(currentStep) &&
            <div className="text-end pt-grid-lg">
                <Button
                    text={strings("button.next")}
                    padding = "py-2 px-10"
                    onClick={() => onClickNext()}
                />
            </div>
            }
        </>
    );
};

export default ResetPassword;