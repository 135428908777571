import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../view/component/button";
import CreateDirectoryStep1 from "../view/component/createDirectoryStep1";
import CreateDirectoryStep2 from "../view/component/createDirectoryStep2";
import CreateCommerialRigistration from "../view/component/createCommerialRigistration";
import CreateProducerLicense from "../view/component/createProducerLicense";
import CreateDirectoryStep5 from "../view/component/createDirectoryStep5";

import { SessionContext } from "../util/context";
import { getDate, getLangCountryURL, priceToPriceWithCurrency, strings } from "../util/formatter";
import endpoint from "../config/endpoint";
import { request } from "../util/http";
import { isNotEmpty, validateFileType, validateFutureDate, validateLettersWithSpaces, validateNumbersOnly } from "../util/validator";

const CreateDirectory = () =>
{
    const { prefs, userInfo, setter, setActiveModal, modalOptions, setModalOptions } = useContext(SessionContext);
    const navigate = useNavigate();
    const [createDirectoryForm, setCreateDirectoryForm] = useState({
        selectedSubscription: {},
        entityName: "",
        crInfo:
        {
            crNumber: "",
            selectedCRCountry: {},
            crExpiryDate: null,
            crFile: {}
        },
        plInfo:
        {
            haveProducerLicense:
            {
                label: strings("common.no"),
                value: false
            },
            plNumber: "",
            selectedPLCountry: {},
            plExpiryDate: null,
            plFile: {}
        }
    });
    const [pricingData, setPricingData] = useState([]);
    const [isDirectoryCreated, setIsDirectoryCreated] = useState(false);
    const [isCRCreated, setIsCRCreated] = useState(false);
    const [isLPCreated, setIsLPCreated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const prev = () =>
    {
        setModalOptions({
            ...modalOptions,
            currentStep: modalOptions.currentStep - 1
        });
    };

    const next = () =>
    {
        if (modalOptions?.currentStep === 2)
        {
            createDirectory();
        }
        else if (modalOptions?.currentStep === 3)
        {
            createCR();
        }
        else if (modalOptions?.currentStep === 4)
        {
            createLP();
        }
        else if (modalOptions?.totalSteps === modalOptions?.currentStep)
        {
            setActiveModal(undefined);
            setModalOptions({});
            navigate(`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard/overview`);
        }
        else
        {
            setModalOptions({
                ...modalOptions,
                currentStep: modalOptions.currentStep + 1
            });
        }
    };

    const fetchPricingData = async () =>
    {
        try
        {
            const res = await request("GET", endpoint.cms.pricing.data);
            const
            {
                basic_fee,
                essentials_fee,
                premium_fee,
                enterprise_fee
            } = res?.info || {}
            setPricingData([
                {
                    key: "basic",
                    label: strings("subscriptionPricing.basic")?.replace("{PRICE}", priceToPriceWithCurrency(prefs?.currency, basic_fee))
                },
                {
                    key: "essentials",
                    label: strings("subscriptionPricing.essentials")?.replace("{PRICE}", priceToPriceWithCurrency(prefs?.currency, essentials_fee))
                },
                {
                    key: "premium",
                    label: strings("subscriptionPricing.premium")?.replace("{PRICE}", priceToPriceWithCurrency(prefs?.currency, premium_fee))
                },
                {
                    key: "enterprise",
                    label: strings("subscriptionPricing.enterprise")?.replace("{PRICE}", priceToPriceWithCurrency(prefs?.currency, enterprise_fee))
                }
            ]);
        }
        catch (error) {}
    };

    const validateForm = (button) =>
    {
        const selectedSubscription = createDirectoryForm?.selectedSubscription;
        if (modalOptions?.currentStep === 1 && button === "next" && isNotEmpty(selectedSubscription?.key))
        {
            return false;
        }

        const entityName = createDirectoryForm?.entityName;
        if (modalOptions?.currentStep === 2 && button === "next" && validateLettersWithSpaces(entityName, undefined, true)?.isValid && isNotEmpty(entityName))
        {
            return false;
        }

        const crInfo = createDirectoryForm?.crInfo;
        if (modalOptions?.currentStep === 3 && button === "next" && validateNumbersOnly(crInfo?.crNumber)?.isValid && isNotEmpty(crInfo?.crNumber) &&
        isNotEmpty(crInfo?.selectedCRCountry?.name) && validateFutureDate(getDate(prefs?.lang, crInfo?.crExpiryDate, "YYYY-MM-DD"))?.isValid &&
        validateFileType(crInfo?.crFile, ["application/pdf"], ["pdf"])?.isValid && isNotEmpty(crInfo?.crFile?.name))
        {
            return false;
        }

        if ((modalOptions?.currentStep === 2 || modalOptions?.currentStep === 3) && button === "prev")
        {
            if (isDirectoryCreated)
            {
                return true;
            }
            else
            {
                return false;
            }
        }

        const plInfo = createDirectoryForm?.plInfo;
        if (modalOptions?.currentStep === 4 && button === "next")
        {
            if (!plInfo?.haveProducerLicense?.value) return false;

            if (plInfo?.haveProducerLicense?.value && validateNumbersOnly(plInfo?.plNumber)?.isValid && isNotEmpty(plInfo?.plNumber) &&
            isNotEmpty(plInfo?.selectedPLCountry?.name) && validateFutureDate(getDate(prefs?.lang, plInfo?.plExpiryDate, "YYYY-MM-DD"))?.isValid &&
            validateFileType(plInfo?.plFile, ["application/pdf"], ["pdf"])?.isValid && isNotEmpty(plInfo?.plFile?.name))
            {
                return false;
            }
        }

        if ((modalOptions?.currentStep === 4) && button === "prev")
        {
            if (isCRCreated)
            {
                return true;
            }
            else
            {
                return false;
            }
        }

        if (modalOptions?.currentStep === 5)
        {
            return false
        }

        return true;
    }

    const createDirectory = async () =>
    {
        setIsLoading(true);
        if (validateForm)
        {
            try
            {
                const data = {
                    subscription_code: createDirectoryForm?.selectedSubscription?.key,
                    legal_name: createDirectoryForm?.entityName,
                    display_name: createDirectoryForm?.entityName,
                    country_code: prefs?.country_code,
                    currency_code: prefs?.currency,
                    contact_email: userInfo?.email,
                    contact_mobile_code: userInfo?.mobile_code,
                    contact_mobile: userInfo?.mobile,
                    lang_code: prefs?.lang,
                    communication_method: "email"
                };

                const res = await request("POST", endpoint.crm.merchant.data, data);

                setter("access_token", res?.info?.access_token);
                setter("refresh_token", res?.info?.refresh_token);
                setIsDirectoryCreated(true);
                setIsLoading(false);
                setModalOptions({
                    ...modalOptions,
                    currentStep: modalOptions.currentStep + 1
                });
            }
            catch (error)
            {
                setIsDirectoryCreated(false);
                console.log(error?.error_msg);
                setIsLoading(false);
            }
        }
    }

    const createCR = async () =>
    {
        setIsLoading(true);
        if (validateForm)
        {
            try
            {
                const data =
                {
                    cr_number: createDirectoryForm?.crInfo?.crNumber,
                    cr_country_code: createDirectoryForm?.crInfo?.selectedCRCountry?.country_code,
                    cr_end_date: getDate(prefs?.lang, createDirectoryForm?.crInfo?.crExpiryDate, "YYYY-MM-DD"),
                    cr_img: createDirectoryForm?.crInfo?.crFile
                };

                const res = await request("POST", endpoint.crm.merchant.cr, data);

                setIsCRCreated(true);
                setIsLoading(false);
                setModalOptions({
                    ...modalOptions,
                    currentStep: modalOptions.currentStep + 1
                });
            }
            catch (error)
            {
                setIsCRCreated(false);
                console.log(error?.error_msg);
                setIsLoading(false);
            }
        }
    }

    const createLP = async () =>
    {
        setIsLoading(true);
        if (validateForm)
        {
            try
            {
                if (createDirectoryForm?.plInfo?.haveProducerLicense?.value)
                {
                    const data =
                    {
                        license_number: createDirectoryForm?.plInfo?.plNumber,
                        country_code: createDirectoryForm?.plInfo?.selectedPLCountry?.country_code,
                        expireation_date: getDate(prefs?.lang, createDirectoryForm?.plInfo?.plExpiryDate, "YYYY-MM-DD"),
                        license_url: createDirectoryForm?.plInfo?.plFile
                    };
                    const res = await request("POST", endpoint.bms.producer.data, data);
                    setIsLPCreated(true);
                    setIsLoading(false);
                    setModalOptions({
                        ...modalOptions,
                        currentStep: modalOptions.currentStep + 1
                    });
                }
                else
                {
                    setIsLPCreated(false);
                    setIsLoading(false);
                    setModalOptions({
                        ...modalOptions,
                        currentStep: modalOptions.currentStep + 1
                    });
                }
            }
            catch (error)
            {
                setIsLPCreated(false);
                console.log(error?.error_msg);
                setIsLoading(false);
            }
        }
    }

    useEffect(() =>
    {
        fetchPricingData();
        // return () => {
        //     setModalOptions({});
        // }
    }, []);

    return (
        <>
            {modalOptions.currentStep === 1
                ? <CreateDirectoryStep1
                    pricingData={pricingData}
                    createDirectoryForm={createDirectoryForm}
                    setCreateDirectoryForm={setCreateDirectoryForm}
                />
                : modalOptions.currentStep === 2
                ? <CreateDirectoryStep2
                    createDirectoryForm={createDirectoryForm}
                    setCreateDirectoryForm={setCreateDirectoryForm}
                />
                : modalOptions.currentStep === 3
                ? <CreateCommerialRigistration
                    commerialRegisterationForm={createDirectoryForm}
                    setCommerialRegisterationForm={setCreateDirectoryForm}
                />
                : modalOptions.currentStep === 4
                ? <CreateProducerLicense
                    producerLicenseForm={createDirectoryForm}
                    setProducerLicenseForm={setCreateDirectoryForm}
                />
                : <CreateDirectoryStep5 />
            }
            <div className={`flex ${modalOptions.currentStep !== 1 ? "justify-between" : "justify-end"} pt-grid-lg`}>
                {modalOptions.currentStep !== 1 &&
                    <Button
                        disabled={isLoading || validateForm("prev")}
                        text={strings("button.previous")}
                        bgColor="bg-secondary-darkest"
                        padding="py-2 px-grid-xl"
                        onClick={prev}
                    />
                }
                <Button
                    disabled={isLoading || validateForm("next")}
                    text={strings("button.next")}
                    bgColor="bg-secondary-darkest"
                    padding="py-2 px-grid-xl"
                    onClick={next}
                />
            </div>
        </>
    );
};

export default CreateDirectory;