import React, { useContext } from "react";

import dayjs from "dayjs";

import DatePicker from "react-datepicker";

import { SessionContext } from "../../util/context";

const DateRangeByFilter = (props) =>
{
    const { prefs } = useContext(SessionContext);

    const {
        value,
        inputClassName,
        isDisabled,
        shouldFilterDateByDay = false,
        dateFormat,
        showMonthYearPicker,
        showQuarterYearPicker,
        showYearPicker,
        setDate,
        showTimeSelect
    } = props;

    return (
        <DatePicker
            disabled={isDisabled}
            className={`${inputClassName} ${isDisabled ? "cursor-not-allowed" : "cursor-pointer"} leading-none rounded-xs font-secondary text-sm p-grid-sm outline-none transition-all duration-fast`}
            dateFormat={(dateFormat) ?? "yyyy-MM-dd"}
            locale={prefs?.lang}
            maxDate={dayjs().toDate()}
            filterDate={shouldFilterDateByDay}
            selected={value}
            showMonthYearPicker={showMonthYearPicker ?? false}
            showQuarterYearPicker={showQuarterYearPicker ?? false}
            showYearPicker={showYearPicker ?? false}
            showTimeSelect={showTimeSelect}
            onChange={(data) => setDate(data)}
        />
    )
}

export default DateRangeByFilter;