import React, { useContext, useEffect, useRef, useState } from "react";

import { AnimatePresence, motion } from "framer-motion";

import SVGIcon from "./svgIcons";

import { SessionContext } from "../../util/context";
import { strings } from "../../util/formatter";

const DropdownMenu = (props) =>
{
    const { prefs, isPostLogin } = useContext(SessionContext);
    const {
        useLocalization = false,
        list,
        labelKey,
        selectedValue,
        placeholder,
        setSelected,
        xAxis = "start-0 sm:start-auto end-0",
        shadow = "shadow-none",
        bgColor = isPostLogin ? "bg-neutral-high-regular" : "bg-secondary-light",
        textColor = isPostLogin ? "text-neutral-low-darkest" : "text-secondary-darkest",
        iconStroke = isPostLogin ? "stroke-neutral-low-darkest" : "stroke-secondary-darkest",
        font = "text-sm",
        width = "w-full",
        roundedStyle = "rounded-xs",
        padding = isPostLogin ? "px-grid-sm py-[14px]" : "p-grid-sm",
        gap = "gap-grid-xxl",
        optionalStyle = "whitespace-nowrap",
        wrapperStyle = "",
        isPhoneDropDown = false,
        disabled = false
    } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [searchKey, setSearchKey] = useState("");
    const target = useRef(null);

    useEffect(() =>
    {
        document.addEventListener("click", clickOutside, true);
        return () =>
        {
            document.removeEventListener("click", clickOutside, true);
        };
    }, []);

    const open = () =>
    {
        setIsOpen(true);
    };

    const select = (item) =>
    {
        setSelected(item);
        setIsOpen(false);
    };

    const clickOutside = (e) =>
    {
        if (!target.current?.contains(e.target))
        {
            setIsOpen(false);
            setSearchKey("")
        }
    };

    return (
        <div className={`${wrapperStyle} relative`}>
            <div
                // eslint-disable-next-line brace-style
                onClick={list?.length > 0 && !disabled ? open : () => {}}
                dir={isPhoneDropDown ? "rtl" : prefs?.dir}
                className={`${(list?.length === 0 || list === undefined || disabled) ? "opacity-70 cursor-not-allowed" : "cursor-pointer"} ${isPostLogin ? (isOpen ? "border-2 border-primary-regular" : "border-2 border-transparent") : ""} transition-colors duration-fast ease-in ${width} ${padding} flex justify-between items-center ${gap} ${font} ${bgColor} ${textColor} ${roundedStyle} ${shadow} ${optionalStyle}`}
            >
                <div className="flex gap-grid-xs items-center">
                    <div dir={isPhoneDropDown ? "ltr" : prefs?.dir} className={`${!selectedValue?.[labelKey] && textColor}`}>
                        {(isPhoneDropDown ? `+${selectedValue?.[labelKey]}` : (useLocalization && selectedValue?.[labelKey] ? strings(selectedValue?.[labelKey]) : selectedValue?.[labelKey]) || placeholder)}
                    </div>
                </div>
                <SVGIcon name="chevron" viewBox="0 0 24 24" width={14} height={14} stroke={iconStroke} className="rotate-90 fill-none"/>
            </div>
            <AnimatePresence>
                {isOpen &&
                <motion.div
                    ref={target}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.7 }}
                    dir={isPhoneDropDown ? "rtl" : prefs?.dir}
                    className={`absolute z-10 top-14 ${xAxis} min-w-[230px] sm:min-w-[300px] w-full h-auto rounded-xs drop-shadow ${isPostLogin ? "border border-neutral-high-regular bg-neutral-high-lightest" : "bg-secondary-lightest"}`}
                >
                    <div className="flex flex-col justify-between">
                        <ul data-lenis-prevent className={`max-h-[200px] min-h-[200px] overflow-scroll rounded-t-xs divide-y divide-solid ${isPostLogin ? "divide-neutral-high-regular" : "divide-primary-light"}`}>
                            {list?.filter(item => item?.[labelKey]?.includes(searchKey))?.map((item, index) =>
                                (
                                    <div
                                        key={index}
                                        dir={isPhoneDropDown ? "ltr" : prefs?.dir}
                                        className={`px-grid-xs py-grid-sm text-md cursor-pointer ${isPostLogin ? "hover:bg-neutral-high-light text-neutral-low-darkest" : "hover:bg-secondary-light text-secondary-darkest"} hover:transition-colors hover:duration-fast hover:ease-linear`}
                                        onClick={() => select(item)}
                                    >
                                        {isPhoneDropDown ? `+${item?.[labelKey]}` : useLocalization ? strings(item?.[labelKey]) : item?.[labelKey]}
                                    </div>
                                ))}
                        </ul>
                        {list?.length > 3 &&
                            <div className={`bg-secondary-light p-grid-xxs rounded-b-xs`}>
                                <input
                                    onChange={(e) => setSearchKey(e.target.value)}
                                    value={searchKey}
                                    autoComplete="off"
                                    placeholder={strings("placeholder.dropdownSearch")}
                                    className={`w-full p-2 focus:outline-none ${isPostLogin ? "bg-neutral-high-lightest text-neutral-low-darkest placeholder:text-neutral-low-darkest/50" : "bg-secondary-lightest text-secondary-darkest placeholder:text-secondary-darkest/50"} rounded-xs`}
                                />
                            </div>
                        }
                    </div>
                </motion.div>}
            </AnimatePresence>
        </div>
    );
};

export default DropdownMenu;