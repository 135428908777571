import React, { createRef, useContext, useEffect, useState } from "react";

import Label from "../view/component/label";
import DropdownMenu from "../view/component/dropdownMenu";
import LineMsg from "../view/component/lineMsg";
import Input from "../view/component/input";
import Button from "../view/component/button";
import FileInput from "../view/component/fileInput";
import Tabs from "../view/component/tabs";

import { SessionContext } from "../util/context";
import { strings } from "../util/formatter";
import { isNotEmpty, validateEmail, validateLettersOnly, validatePassword, validateSameLanguage } from "../util/validator";
import { request } from "../util/http";
import endpoint from "../config/endpoint";

export const LANGUAGE = [
    {
        label: "العربية",
        value: "ar"
    },
    {
        label: "الإنجليزية",
        value: "en"
    }
];

const UserAccount = () =>
{
    const { userInfo, isPostLogin, setActiveModal, setter } = useContext(SessionContext);
    const profileImgRef = createRef(null);
    const [active, setActive] = useState("profile");
    const [isLoading, setIsLoading] = useState(false);
    const [firstName, setFirstName] = useState(userInfo?.first_name ? userInfo?.first_name : null);
    const [lastName, setLastName] = useState(userInfo?.last_name ? userInfo?.last_name : null);
    const [email, setEmail] = useState(userInfo?.email ? userInfo?.email : "");
    const [profileImg, setProfileImg] = useState({});
    const [langValue, setLangValue] = useState(userInfo?.lang_code ? userInfo?.lang_code : "");
    const [language, setLanguage] = useState(LANGUAGE?.filter(item => item.value === userInfo?.lang_code)?.length > 0 ? LANGUAGE?.filter(item => item.value === userInfo?.lang_code)?.[0] : {});
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const isValidForm = active === "profile"
    ? (firstName !== userInfo?.first_name || lastName !== userInfo?.last_name) &&
    (userInfo?.first_name?.length > 0 ? firstName !== "" : true) && (userInfo?.last_name?.length > 0 ? lastName !== "" : true) &&
    isNotEmpty(email) && validateSameLanguage(firstName, lastName)?.isValid &&
    validateLettersOnly(firstName)?.isValid &&
    validateLettersOnly(lastName)?.isValid &&
    validateEmail(email)?.isValid
    : isNotEmpty(password) && isNotEmpty(newPassword) && isNotEmpty(confirmNewPassword) &&
    validatePassword(newPassword)?.isValid && validatePassword(confirmNewPassword)?.isValid &&
    newPassword === confirmNewPassword

    const selectProfileImg = () =>
    {
        if (profileImgRef.current !== null)
        {
            profileImgRef.current?.click();
        }
    };

    const setPrefferedLang = (item) =>
    {
        setLangValue(item?.value);
        setLanguage(item);
    };

    const save = async () =>
    {
        if (isValidForm || profileImg?.name)
        {
            setIsLoading(true);
            if (profileImg?.name && active === "profile")
            {
                try
                {
                    const userAvatarRes = await request("POST", endpoint.crm.user.avatar, { avatar_img: profileImg });
                    if (!isValidForm)
                    {
                        setIsLoading(false);
                        setActiveModal(undefined);
                    }
                }
                catch (error)
                {
                    if (!isValidForm)
                    {
                        setIsLoading(false);
                    }
                }
            };

            if (isValidForm && active === "profile")
            {
                try
                {
                    const data = {
                        ...(isNotEmpty(firstName) && firstName !== userInfo?.first_name && { first_name: firstName }),
                        ...(isNotEmpty(lastName) && lastName !== userInfo?.last_name && { last_name: lastName }),
                        ...(isNotEmpty(email) && email !== userInfo?.email && { email: email }),
                        ...(isNotEmpty(langValue) && langValue !== userInfo?.lang_code && { lang_code: langValue })
                    }
                    const queryString = Object.keys(data)
                    ?.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
                    ?.join("&");
                    const userDataPutRes = await request("PUT", `${endpoint.crm.user.data}?${queryString}`);
                    const userInfoObj = { ...userInfo, ...data };
                    setter("user_info", JSON.stringify(userInfoObj));
                    // ...(Object.keys(profileImg)?.length > 0 && { email: profileImg }),
                    setIsLoading(false);
                    setActiveModal(undefined);
                }
                catch (error)
                {
                    setIsLoading(false);
                }
            }

            if (active === "password")
            {
                try
                {
                    const data = {
                        old_password: password,
                        new_password: newPassword
                    }
                    const passwordPostRes = await request("POST", endpoint.crm.user.password, data);
                    setIsLoading(false);
                    setActiveModal(undefined);
                }
                catch (error)
                {
                    setIsLoading(false);
                }
            }
        }
    };

    useEffect(() =>
    {
        setFirstName(userInfo?.first_name ? userInfo?.first_name : null);
        setLastName(userInfo?.last_name ? userInfo?.last_name : null);
        setEmail(userInfo?.email ? userInfo?.email : "");
        setProfileImg(userInfo?.avatar_img ? userInfo?.avatar_img : {});
        setLangValue(userInfo?.lang_code ? userInfo?.lang_code : "");
        setLanguage(LANGUAGE?.filter(item => item.value === userInfo?.lang_code)?.length > 0 ? LANGUAGE?.filter(item => item.value === userInfo?.lang_code)?.[0] : {});
        setLanguage(LANGUAGE?.filter(item => item.value === userInfo?.lang_code)?.length > 0 ? LANGUAGE?.filter(item => item.value === userInfo?.lang_code)?.[0] : {});
        setPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
    }, [active]);

    return (
        <>
            <Tabs
                active={active}
                setActive={setActive}
                isModal={true}
                list={[
                    { key: "profile", label: strings("common.profile") },
                    { key: "password", label: strings("common.password") }
                ]}
                shouldShowAll={false}
            >
                <div className={`p-grid-md ${isPostLogin ? "text-neutral-low-darkest" : "text-secondary-darkest"} font-secondary`}>
                    {active === "profile"
                        ? <>
                            <div className="flex flex-col sm:flex-row justify-between sm:gap-grid-xl">
                                <div className="w-full pb-grid-sm">
                                    <Label label={strings("label.firstName")}/>
                                    <Input
                                        name="firstName"
                                        value={firstName}
                                        setValue={setFirstName}
                                    />
                                    <LineMsg msg={validateSameLanguage(firstName, lastName)?.isValid ? validateLettersOnly(firstName)?.msg : validateSameLanguage(firstName, lastName)?.msg} type="error"/>
                                </div>
                                <div className="w-full pb-grid-sm">
                                    <Label label={strings("label.lastName")}/>
                                    <Input
                                        name="lastName"
                                        value={lastName}
                                        setValue={setLastName}
                                    />
                                    <LineMsg msg={validateLettersOnly(lastName)?.msg} type="error"/>
                                </div>
                            </div>
                            <div className="w-full pb-grid-sm">
                                <Label label={strings("label.email")}/>
                                <Input
                                    name="email"
                                    value={email}
                                    setValue={setEmail}
                                />
                                <LineMsg msg={isNotEmpty(email) ? validateEmail(email)?.msg : strings("message.emptyEmail")} type="error"/>
                            </div>
                            <div className="w-full pb-grid-sm">
                                <Label label={strings("label.profileImage")}/>
                                <FileInput
                                    fileRef={profileImgRef}
                                    value={profileImg}
                                    setValue={setProfileImg}
                                    selectFile={selectProfileImg}
                                />
                                <LineMsg type="error"/>
                            </div>
                            <div className="w-1/2 pb-grid-sm">
                                <Label label={strings("label.language")}/>
                                <DropdownMenu
                                    list={LANGUAGE}
                                    labelKey="label"
                                    placeholder={strings("placeholder.selectLang")}
                                    selectedValue={language}
                                    setSelected={setPrefferedLang}
                                />
                                <LineMsg type="error"/>
                            </div>
                        </>
                        : <>
                            <div className="w-full pb-grid-sm">
                                <Label label={strings("label.password")}/>
                                <Input
                                    name="password"
                                    value={password}
                                    type="password"
                                    placeholder={strings("placeholder.password")}
                                    setValue={setPassword}
                                />
                                <LineMsg type="error"/>
                            </div>
                            <div className="w-full pb-grid-sm">
                                <Label label={strings("label.newPassword")}/>
                                <Input
                                    name="newPassword"
                                    value={newPassword}
                                    type="password"
                                    placeholder={strings("placeholder.password")}
                                    setValue={setNewPassword}
                                />
                                <LineMsg msg={validatePassword(newPassword)?.msg} type="error"/>
                            </div>
                            <div className="w-full pb-grid-sm">
                                <Label label={strings("label.confirmNewPassword")}/>
                                <Input
                                    name="confirmPassword"
                                    value={confirmNewPassword}
                                    type="password"
                                    placeholder={strings("placeholder.password")}
                                    setValue={setConfirmNewPassword}
                                />
                                <LineMsg msg={newPassword !== confirmNewPassword ? strings("message.passwordDoesNotMatch") : ""} type="error"/>
                            </div>
                        </>
                    }
                </div>
            </Tabs>
            <div className="text-end pt-grid-lg px-grid-md pb-grid-md">
                <Button
                    disabled={profileImg?.name ? false : (!isValidForm || isLoading)}
                    text={strings("button.save")}
                    padding="py-2 px-grid-xl"
                    onClick={save}
                />
            </div>
        </>
    );
};

export default UserAccount;