import React, { useContext } from "react";

import DropdownMenu from "./dropdownMenu";
import RemovableItemWithDropdown from "./removableItemWithDropdown";

import { strings, truncateString } from "../../util/formatter";
import constant from "../../config/constant";
import { SessionContext } from "../../util/context";
import Button from "./button";

export const USER_ROLE = [
    {
        label: "مالك",
        value: "owner"
    },
    {
        label: "مشرف",
        value: "admin"
    }
];

const ManageEntityUsers = (props) =>
{
    const { prefs, roleCode } = useContext(SessionContext);
    const {
        entityUsers,
        userRole,
        shouldLoadMore,
        setRemovedUserId,
        setUpdatedRole,
        setEntityUsers,
        setUserRole,
        loadMore
    } = props;
    const isDisabled = roleCode !== "owner" || (userRole?.value === "owner" && entityUsers?.length === 1);

    const removeUser = (item) =>
    {
        if (!isDisabled)
        {
            const temp = entityUsers;
            const arr = temp?.filter(user => user.user_id !== item.user_id);
            setEntityUsers([...arr]);
            setRemovedUserId(item.user_id);
        }
    };

    const editUserRole = (role, item) =>
    {
        if (!isDisabled)
        {
            const temp = entityUsers;
            const foundItem = temp?.find(role => role.value === item.value);
            if (foundItem)
            {
                foundItem.role = role;
                setEntityUsers([...temp]);
                setUpdatedRole({
                    user_id: foundItem?.user_id,
                    role
                })
            }
        }
    };

    return (
        <>
            <div className="flex w-full pb-grid-xl justify-end">
                <DropdownMenu
                    list={constant.user_role}
                    labelKey={prefs?.lang === constant.lang.values[0] ? "label_ar" : "label_en"}
                    selectedValue={userRole}
                    setSelected={setUserRole}
                />
            </div>
            {entityUsers?.length > 0
            ? entityUsers?.map((user, index) =>
                    (
                        <RemovableItemWithDropdown
                            key={index}
                            index={index}
                            item={user}
                            placeholder={strings("placeholder.userType")}
                            useLabel={true}
                            label={truncateString(user?.first_name ? `${user?.first_name} ${user?.last_name}` : user?.email, 50)}
                            disableDeleteAndUpdate={isDisabled}
                            dropdownLabel={prefs?.lang === constant.lang.values[0] ? "label_ar" : "label_en"}
                            selected={user?.role?.value ? user?.role : userRole}
                            dropdownList={constant.user_role}
                            listLength={entityUsers?.length}
                            addValue={(e) => editUserRole(e, user)}
                            removeItem={() => removeUser(user)}
                        />
                    ))
            : <p className="text-center">{strings("empty.manageUsers")}</p>
            }
            {shouldLoadMore && entityUsers?.length > 0 &&
                (
                    <div className="text-center mt-grid-xxl">
                        <Button
                            text={strings("common.loadMore")}
                            onClick={loadMore}
                        />
                    </div>
                )}
        </>
    );
};

export default ManageEntityUsers;