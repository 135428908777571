import React, { useContext } from "react";

import { Link } from "react-router-dom";

import { getLangCountryURL, strings } from "../../util/formatter";
import { SessionContext } from "../../util/context";

const CreateDirectoryStep5 = () =>
{
    const { prefs } = useContext(SessionContext);

    return (
        <>
            <div className="w-full pt-grid-md pb-grid-sm">
                <p className="pb-grid-xs font-primary text-md">
                    {strings("message.createDirectoryAgreementMessage")}
                </p>
                <div className="pb-grid-xs">
                    <Link
                        className="text-md underline decoration-dotted"
                        to={`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/page/terms_of_use`}
                        target="_blank"
                    >
                        {strings("title.termsOfUse")}
                    </Link>
                </div>
                <div className="pb-grid-xs">
                    <Link
                        className="text-md underline decoration-dotted"
                        to={`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/page/publisher_agreement`}
                        target="_blank"
                    >
                        {strings("title.publishersAgreement")}
                    </Link>
                </div>
                <div className="pb-grid-xs">
                    <Link
                        className="text-md underline decoration-dotted"
                        to={`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/page/seller_agreement`}
                        target="_blank"
                    >
                        {strings("title.sellersAgreement")}
                    </Link>
                </div>
            </div>
        </>
    );
};

export default CreateDirectoryStep5;