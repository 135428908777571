import React, { useContext } from "react";
import { SessionContext } from "../../util/context";

const Input = (props) =>
{
    const { isPostLogin } = useContext(SessionContext);
    const { name, value, type = "text", placeholder, setValue, optionalStyle = undefined, validateLength = false, maxLength } = props;

    const lengthProp = { ...(validateLength ? { maxLength } : {}) }

    return (
        <input
            onChange={(e) => setValue(e.target.value)}
            name={name}
            type={type}
            value={value}
            placeholder={placeholder}
            autoComplete="off"
            {...lengthProp}
            className={optionalStyle || `w-full px-grid-sm ${isPostLogin ? "py-[14px] bg-neutral-high-regular text-neutral-low-darkest placeholder:text-neutral-low-darkest/50 border-2 border-transparent focus:border-primary-regular" : "py-grid-sm bg-secondary-light text-secondary-darkest placeholder:text-secondary-darkest/50"} text-sm rounded-xs focus:outline-none transition-colors duration-fast ease-in`}
        />
    );
};

export default Input;