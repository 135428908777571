import React from "react";

import SVGIcon from "./svgIcons";

const BookCard = (props) =>
{
    const { rank, shouldShowRankText = false, period = -1, rankPrevious } = props;
    return (
        <div className="flex gap-1 justify-center items-center font-primary">
            <div className={`w-[21px] h-[21px] flex justify-center items-center rounded-full ${period === 0 ? "bg-amber-500" : rankPrevious > 0 ? "bg-emerald-500" : rankPrevious < 0 ? "bg-red-500" : "bg-gray-500"}`}>
                {period === 0
                ? <SVGIcon viewBox="0 0 25 25" name="star" width={16} height={16} stroke="stroke-primary-lightest"/>
                : rankPrevious > 0
                    ? <SVGIcon name="chevron" viewBox="0 0 24 24" width={17} height={17} stroke="stroke-primary-lightest" className="-rotate-90 fill-none"/>
                    : rankPrevious < 0
                        ? <SVGIcon name="chevron" viewBox="0 0 24 24" width={17} height={17} stroke="stroke-primary-lightest" className="rotate-90 fill-none"/>
                        : <SVGIcon name="minus" stroke="stroke-primary-lightest"/>
                }
            </div>
            {shouldShowRankText &&
                <p className="text-lg">{rank}</p>
            }
        </div>
    );
};

export default BookCard;