import React, { useState } from "react";

import LineMsg from "./lineMsg";
import Label from "./label";
import DropdownMenu from "./dropdownMenu";

import { strings } from "../../util/formatter";

const CAUTIONS =
[
    strings("common.CreateDirectoryWarning"),
    strings("common.signupCaution")
]

const CreateDirectoryStep1 = ({ pricingData, createDirectoryForm, setCreateDirectoryForm }) =>
{
    const {
        selectedSubscription
    } = createDirectoryForm || {};

    const onSelectSubscriptionType = (type) =>
    {
        setCreateDirectoryForm({ ...createDirectoryForm, selectedSubscription: type });
    };

    return (
        <>
            <div className="w-full">
                <Label label={strings("label.subscriptionType")}/>
                <DropdownMenu
                    useLocalization={true}
                    list={pricingData}
                    labelKey="label"
                    placeholder={strings("placeholder.subscriptionType")}
                    selectedValue={selectedSubscription}
                    setSelected={onSelectSubscriptionType}
                />
                <LineMsg type="error"/>
            </div>
            <div className="text-secondary-darkest">
                {CAUTIONS?.map((item, index) =>
                    (
                        <div key={index} className="flex justify-start items-start">
                            <span className="text-[25px] pe-2">&#8226;</span>
                            <span className="pt-[7px]">{item}</span>
                        </div>
                    ))}
            </div>
        </>
    );
};

export default CreateDirectoryStep1;