import React, { Fragment, useContext, useEffect } from "react";

import { getDate, getLangCountryURL, strings } from "../util/formatter";
import { SessionContext } from "../util/context";
import { useNavigate } from "react-router-dom";

const NotificationModal = () =>
{
    const { prefs, announcements, setter, setActiveModal, setModalOptions } = useContext(SessionContext);
    const navigate = useNavigate();

    const action = (item) =>
    {
        if (item?.distination)
        {
            if (item?.destinationType === "modal")
            {
                setModalOptions(item?.options);
                setActiveModal(item?.distination);
            }
            else
            {
                navigate(`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/${item?.distination}`);
            }
        }
    }

    useEffect(() =>
    {
        return () => {
            const updatedAnnouncements = announcements?.map(announcement => ({ ...announcement, read: true }));
            setter("announcements", JSON.stringify(updatedAnnouncements));
            setModalOptions({});
        }
    }, []);

    return (
        announcements?.length > 0
        ? announcements?.map((item, index) => (
            <Fragment key={index}>
                <div className={`${item?.read ? "bg-neutral-high-light" : ""} ${index === announcements?.length - 1 ? "rounded-b-lg" : ""} p-grid-md flex flex-row justify-between items-center`}>
                    <div className="text-base whitespace-nowrap">
                        {item?.msg}
                        {item?.type !== "msg" &&
                            <span className={`ps-grid-xs underline cursor-pointer hover:opacity-70 transition-opacity duration-too-fast ease-in`} onClick={() => action(item)}>
                                {strings("button.byClickingHere")}
                            </span>
                        }
                    </div>
                    <div className="whitespace-nowrap">
                        {getDate(prefs?.lang, item?.creation_date)}
                    </div>
                </div>
                {index !== announcements?.length - 1 &&
                    <hr className="w-full h-[1px] bg-neutral-high-regular border-none"/>
                }
            </Fragment>
        ))
        : <div className="p-grid-md">
            <p className="text-center">{strings("empty.notification")}</p>
        </div>
    );
};

export default NotificationModal;