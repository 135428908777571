import React, { createRef, useContext, useState } from "react";

import LineMsg from "./lineMsg";
import Label from "./label";
import Input from "./input";
import DropdownMenu from "./dropdownMenu";
import DatePickerButton from "./datePickerButton";
import FileInput from "./fileInput";

import { getDate, strings } from "../../util/formatter";
import { validateFileType, validateFutureDate, validateNumbersOnly } from "../../util/validator";
import { SessionContext } from "../../util/context";

const CreateCommerialRigistration = ({ commerialRegisterationForm, setCommerialRegisterationForm }) =>
{
    const { prefs } = useContext(SessionContext);

    const crFileRef = createRef(null);
    const {
        crInfo
    } = commerialRegisterationForm || {};

    const {
        crNumber,
        selectedCRCountry,
        crExpiryDate,
        crFile
    } = crInfo || {};

    const CR_ISSUED_COUNTRY = [
        {
            id: 1,
            name: strings("country.sa"),
            country_code: "sa"
        }
    ];

    const selectCRFile = () =>
    {
        if (crFileRef.current !== null)
        {
            crFileRef.current.click();
        }
    };

    const setCRForm = (value, key) =>
    {
        setCommerialRegisterationForm({ ...commerialRegisterationForm, crInfo: { ...crInfo, [key]: value } });
    };

    return (
        <>
            <div className="pb-grid-sm">
                <Label label={strings("label.commercialRegistrationNumber")}/>
                <Input
                    name="commercialRegistrationNumber"
                    value={crNumber}
                    setValue={(e) => { setCRForm(e, "crNumber") }}
                />
                <LineMsg msg={validateNumbersOnly(crNumber)?.msg} type="error"/>
            </div>
            <div className="flex justify-between gap-grid-xl">
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.commercialRegistrationIssuedCountry")}/>
                    <DropdownMenu
                        list={CR_ISSUED_COUNTRY}
                        labelKey="name"
                        placeholder={strings("placeholder.selectCountry")}
                        selectedValue={selectedCRCountry}
                        setSelected={(e) => { setCRForm(e, "selectedCRCountry") }}
                    />
                    <LineMsg type="error"/>
                </div>
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.commercialRegistrationExpiryDate")}/>
                    <DatePickerButton
                        value={crExpiryDate}
                        setValue={(e) => { setCRForm(e, "crExpiryDate") }}
                    />
                    <LineMsg msg={validateFutureDate(getDate(prefs?.lang, crExpiryDate, "YYYY-MM-DD"))?.msg} type="error"/>
                </div>
            </div>
            <div className="pb-grid-sm">
                <Label label={strings("label.commercialRegistrationFile")}/>
                <FileInput
                    fileRef={crFileRef}
                    value={crFile}
                    setValue={(e) => { setCRForm(e, "crFile") }}
                    selectFile={selectCRFile}
                    acceptedTypes=".pdf"
                />
                <LineMsg msg={validateFileType(crFile, ["application/pdf"], ["pdf"])?.msg} type="error"/>
            </div>
        </>
    );
};

export default CreateCommerialRigistration;