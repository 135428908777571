import constant from "../config/constant";
import i18next from "i18next";

const LocalStorage =
{
    init: async () =>
    {
        localStorage.setItem("access_token", "");
        localStorage.setItem("refresh_token", "");
        localStorage.setItem("user_info", "");
        localStorage.setItem("merchant_info", "");
        localStorage.setItem("prefs", JSON.stringify(
        {
            lang: constant?.lang?.default,
            country_code: constant?.country?.default,
            dir: constant?.direction?.default,
            dir_alt: constant?.direction?.alternative,
            theme: constant?.theme?.default,
            currency: constant?.currency?.default
        }));
        localStorage.setItem("is_logged_in", "0");
    },

    set: async (key, value, prefsKey = undefined) =>
    {
        if (prefsKey === "lang")
        {
            localStorage.setItem("prefs", JSON.stringify(
            {
                ...value,
                lang: value?.lang === constant.lang.values[0] ? constant.lang.values[0] : constant.lang.values[1],
                dir: value?.lang === constant.lang.values[0] ? constant.direction.values[0] : constant.direction.values[1],
                dir_alt: value?.lang === constant.lang.values[0] ? constant.direction.values[1] : constant.direction.values[0]
            }));
            i18next.changeLanguage(value?.lang === constant.lang.values[0] ? constant.lang.values[0] : constant.lang.values[1]);
            i18next.dir(value?.lang === constant.lang.values[0] ? constant.direction.values[0] : constant.direction.values[1]);
        }
        else if (prefsKey === "dir")
        {
            const isNotValidDirection = constant?.direction?.values?.indexOf(value?.dir?.toLowerCase()) === -1;
            localStorage.setItem("prefs", JSON.stringify(
            {
                ...value,
                dir: isNotValidDirection ? constant?.direction?.default : value?.dir?.toLowerCase(),
                dir_alt: isNotValidDirection ? constant?.alternative?.default : value?.dir?.toLowerCase() === "ltr" ? "rtl" : "ltr"
            }));
        }
        else if (prefsKey === "theme")
        {
            const isNotValidTheme = constant?.theme?.values?.indexOf(value?.theme?.toLowerCase()) === -1
            localStorage.setItem("prefs", JSON.stringify(
            {
                ...value,
                theme: isNotValidTheme ? constant?.theme?.default : value?.theme?.toLowerCase()
            }));
        }
        else if (prefsKey === "currency")
        {
            const isNotValidCurrency = constant?.currency?.values?.indexOf(value?.currency?.toLowerCase()) === -1;
            localStorage.setItem("prefs", JSON.stringify(
            {
                ...value,
                currency: isNotValidCurrency ? constant?.currency?.default : value?.currency?.toLowerCase()
            }));
        }
        else if (key === "is_logged_in")
        {
            const isNotValidIsLoggedIn = ["0", "1"]?.indexOf(value?.toLowerCase()) === -1
            localStorage.setItem(key?.toLowerCase(), isNotValidIsLoggedIn ? "0" : value);
        }
        else if (key !== "prefs")
        {
            localStorage.setItem(key?.toLowerCase(), value);
        }
    },

    unset: async (key) =>
    {
        localStorage.removeItem(key);
    },

    get: async (key) =>
    {
        return (localStorage.getItem(key) !== undefined && localStorage.getItem(key) !== null) ? localStorage.getItem(key) : undefined;
    },

    destroy: async () =>
    {
        localStorage.clear();

        this.init();
    }
};

export default LocalStorage;