import React, { useState } from "react";

import LineMsg from "./lineMsg";
import Label from "./label";
import Input from "./input";
import DropdownMenu from "./dropdownMenu";

import { strings } from "../../util/formatter";
import { validateLettersWithSpaces } from "../../util/validator";

const ManageEntityDetails = ({ entityName, setEntityName }) =>
{
    return (
        <div className="w-full">
            <Label label={strings("label.entityNameInCR")}/>
            <Input
                name="entityName"
                value={entityName}
                setValue={setEntityName}
            />
            <LineMsg msg={validateLettersWithSpaces(entityName, undefined, true)?.msg} type="error"/>
        </div>
    );
};

export default ManageEntityDetails;