import React, { useEffect, useState } from "react";

import DropdownMenu from "./dropdownMenu";
import Input from "./input";

const PhoneNumberInput = (props) =>
{
    const {
        countryList,
        phoneNumber,
        countryCode,
        setPhoneNumber,
        setCountryCode
    } = props;

    const COUNTRY_CODE =
    [
        {
            country_code: "sa",
            mobile_code: "966",
            mobile_example: "512345678",
            name_ar: "السعودية",
            name_en: "Saudi Arabia",
            is_default: true,
            is_served: true,
            mobile_prefix: "5",
            mobile_skipped_digit: "0"
        }
    ];

    const SORTED_COUNTRY_LIST =
    [
        ...countryList?.filter((item) => item?.mobile_code === "966"),
        ...countryList?.filter((item) => item?.mobile_code !== "966")
    ];

    useEffect(() =>
    {
        if (phoneNumber?.length > 8 && countryCode?.mobile_code === "966")
        {
            setPhoneNumber(phoneNumber?.substring(0, 9));
        }
    }, [countryCode]);

    return (
    <div dir="ltr" className="flex items-center gap-4">
        <DropdownMenu
            list={SORTED_COUNTRY_LIST || COUNTRY_CODE}
            labelKey="mobile_code"
            selectedValue={countryCode}
            setSelected={setCountryCode}
            gap="gap-grid-xs"
            width="w-[100px]"
            isPhoneDropDown={true}
        />
        <Input
            name="phoneNumber"
            value={phoneNumber}
            placeholder={"XXXXXXXXX"}
            setValue={setPhoneNumber}
            validateLength={countryCode?.mobile_code === "966"}
            maxLength="9"
        />
    </div>
    );
};

export default PhoneNumberInput;