import axios from "axios";
import { strings } from "./formatter";
import constant from "../config/constant";
import endpoint from "../config/endpoint";

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
});

const getHeaders = () =>
{
    const token = localStorage.getItem("access_token");
    const lang = JSON.parse(localStorage.getItem("prefs"))?.lang || "ar";

    return {
        "x-api-key": process.env.REACT_APP_API_KEY,
        "x-api-version": process.env.REACT_APP_API_VERSION,
        "x-api-endpoint": process.env.REACT_APP_API_ENDPOINT,
        ...(token && { "x-api-token": token }),
        "x-api-lang": lang
    };
};

const refreshToken = async () =>
{
    const refresh_token = localStorage.getItem("refresh_token");
    if (!refresh_token)
    {
        throw new Error(strings("message.sessionExpired"));
    }

    try
    {
        const response = await axios.put(
            `${process.env.REACT_APP_API_BASE_URL}${endpoint.crm.user.token}`,
            { refresh_token },
            { headers: getHeaders() }
        );

        const { access_token, refresh_token: new_refresh_token } = response.data?.data?.info;
        localStorage.setItem("access_token", access_token);
        localStorage.setItem("refresh_token", new_refresh_token);

        return access_token;
    }
    catch (error)
    {
        throw new Error(strings("message.sessionExpired"));
    }
};

const handleError = (response) =>
{
    const errorCode = response?.data?.error_code || 400;
    const errorMsg = response?.data?.error_msg || strings("message.genericError");

    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({ error_code: errorCode, error_msg: errorMsg });
};

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) =>
{
    failedQueue.forEach((prom) =>
    {
        if (error)
        {
            prom.reject(error);
        }
        else
        {
            prom.resolve(token);
        }
    });
    failedQueue = [];
};

apiClient.interceptors.request.use(
    (config) =>
    {
        config.headers = getHeaders();
        if (config.method === 'post')
        {
            config.headers["Content-Type"] = "multipart/form-data";
        }
        return config;
    },
    (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
    (response) =>
    {
        return response.data.is_successful
            ? response.data.data
            : handleError(response);
    },
    async (error) =>
    {
        const originalRequest = error.config;

        if (error.response?.data?.error_code === constant?.interceptor?.token?.[0] && !originalRequest._retry)
        {
            if (isRefreshing)
            {
                return new Promise((resolve, reject) =>
                {
                    failedQueue.push({ resolve, reject });
                })
                    .then((token) =>
                    {
                        originalRequest.headers["x-api-token"] = token;
                        return apiClient(originalRequest);
                    })
                    .catch((err) => Promise.reject(err));
            }

            originalRequest._retry = true;
            isRefreshing = true;

            try
            {
                const newToken = await refreshToken();
                processQueue(null, newToken);
                originalRequest.headers["x-api-token"] = newToken;
                return apiClient(originalRequest);
            }
            catch (err)
            {
                processQueue(err, null);
                throw err;
            }
            finally
            {
                isRefreshing = false;
            }
        }

        if (error.response?.status === 400)
        {
            return handleError(error.response);
        }

        throw new Error(strings("message.genericError"));
    }
);

export const request = async (method, url, body = {}) =>
{
    return apiClient.request({ method, url, data: body });
};
