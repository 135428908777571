import React, { useContext } from "react";

import DropdownMenu from "./dropdownMenu";
import SVGIcon from "./svgIcons";

import { SessionContext } from "../../util/context";

const RemovableItemWithDropdown = (props) =>
{
    const { isPostLogin } = useContext(SessionContext)
    const {
        item,
        placeholder,
        useLabel = false,
        label,
        labelKey,
        disableDeleteAndUpdate = false,
        dropdownLabel,
        selected,
        dropdownList = [],
        addValue,
        removeItem
    } = props;
    return (
        <div className="flex justify-between items-center p-grid-xs gap-grid-xxs">
            <div className="text-md whitespace-nowrap">{useLabel ? label : item[labelKey]}</div>
            <div className="flex gap-grid-sm items-center">
                <DropdownMenu
                    disabled={disableDeleteAndUpdate}
                    list={dropdownList}
                    labelKey={dropdownLabel}
                    placeholder={placeholder}
                    selectedValue={selected}
                    setSelected={(e) => addValue(e, item)}
                    width="w-[130px] sm:w-[160px]"
                    gap="gap-grid-xs"
                />
                <div onClick={() => removeItem(item)} className={`${disableDeleteAndUpdate ? "opacity-50 cursor-not-allowed" : "cursor-pointer hover:opacity-70"} transition-opacity duration-fast`}>
                    <SVGIcon name="closeInCircle" width={24} height={24} viewBox="0 0 44 44" className={`${isPostLogin ? "fill-neutral-low-darkest" : "fill-secondary-darkest"}`}/>
                </div>
            </div>
        </div>
    );
};

export default RemovableItemWithDropdown;