const constant =
{
    lang:
    {
        default: "ar",
        values: ["ar", "en"]
    },

    direction:
    {
        default: "rtl",
        alternative: "ltr",
        values: ["rtl", "ltr"]
    },

    currency:
    {
        default: "sar",
        values: ["sar"]
    },

    theme:
    {
        default: "light",
        values: ["light", "dark"]
    },

    header_search_input_path: ["charts", "catalog", "new-arrival", "product", "producer", "contributor", "seller", "page", "search", "error"],

    hide_footer_details_path: ["directory"],

    hide_footer: ["error"],

    otp:
    {
        channel:
        {
            default: "sms",
            values: ["sms", "email"]
        },
        duration: 120,
        length: 6
    },

    country:
    {
        default: "sa",
        values: ["sa", "bh", "ae", "kw", "qa"]
    },

    validLocales: ['ar-sa', 'en-sa', 'ar-ae', 'en-ae'],

    mobile_code:
    {
        defualt: "966"
    },

    mobile:
    {
        prefix: "5",
        length: 12
    },

    privilege: ["owner", "maintainer", "observer"],

    pagination:
    {
        menu:
        {
            default: 3
        },
        list:
        {
            default: 25
        }
    },

    animation:
    {
        speed:
        {
            slowest: 9000,
            slow: 7000,
            normal: 5000,
            fast: 3000,
            fastest: 1000
        }
    },

    main_page:
    [
        {
            key: "charts",
            path: "charts/product",
            title: "title.charts",
            sortId: 0
        },
        {
            key: "newArrival",
            path: "new-arrival",
            title: "title.newArrival",
            sortId: 1
        },
        {
            key: "products",
            path: "catalog/all",
            title: "title.productsCatalog",
            sortId: 2
        },
        {
            key: "logo",
            sortId: 4
        },
        {
            key: "signup",
            isModal: true,
            title: "title.newAccount",
            sortId: 5
        },
        {
            key: "login",
            isModal: true,
            title: "title.login",
            sortId: 6
        },
        {
            key: "langCountry",
            sortId: 7
        }
    ],

    menu_section:
    [
        {
            key: "overview",
            path: "overview",
            title: "title.overview",
            icon: "chart",
            sortId: 0,
            items: []
        },
        // {
        //     key: "heatmap",
        //     path: "heatmap",
        //     title: "title.heatMap",
        //     icon: "home",
        //     sortId: 1,
        //     items: []
        // },
        {
            key: "/dashboard/trend",
            title: "title.performanceServices",
            icon: "row",
            sortId: 2,
            items: [
                {
                    key: "trend/product",
                    path: "trend/product",
                    title: "title.trendProduct",
                    sortId: 2
                },
                {
                    key: "trend/contributor",
                    path: "trend/contributor",
                    title: "title.trendContributor",
                    sortId: 3
                },
                {
                    key: "trend/producer",
                    path: "trend/producer",
                    title: "title.trendProducer",
                    sortId: 4
                },
                // {
                //     key: "trend/distributor",
                //     path: "trend/distributor",
                //     title: "title.trendDistributor",
                //     sortId: 5
                // },
                // {
                //     key: "trend/outlet",
                //     path: "trend/outlet",
                //     title: "title.trendOutlet",
                //     sortId: 6
                // },
                {
                    key: "trend/region",
                    path: "trend/region",
                    title: "title.trendRegion",
                    sortId: 5
                },
                {
                    key: "trend/category",
                    path: "trend/category",
                    title: "title.trendCategory",
                    sortId: 6
                }
                // {
                //     key: "trend/age",
                //     path: "trend/age",
                //     title: "title.trendAge",
                //     sortId: 9
                // }
            ]
        },
        {
            key: "/dashboard/producer",
            title: "title.producerServices",
            icon: "book",
            sortId: 3,
            items: [
                {
                    key: "producer/product-list",
                    path: "producer/product-list",
                    title: "title.productList",
                    sortId: 0
                }
                // {
                //     key: "producer/product-download",
                //     path: "producer/product-download",
                //     title: "title.productDownload",
                //     sortId: 1
                // },
                // {
                //     key: "producer/product-sharing",
                //     path: "producer/product-sharing",
                //     title: "title.productSharing",
                //     sortId: 2
                // }
            ]
        }
        // {
        //     key: "/dashboard/distributor",
        //     title: "title.distributorServices",
        //     icon: "shop",
        //     sortId: 4,
        //     items: [
        //         {
        //             key: "distributor/outlet-list",
        //             path: "distributor/outlet-list",
        //             title: "title.outletList",
        //             sortId: 0
        //         },
        //         {
        //             key: "distributor/report-list",
        //             path: "distributor/report-list",
        //             title: "title.reportList",
        //             sortId: 1
        //         }
        //     ]
        // }
    ],

    map_theme:
    {
        dark:
        [
            {
                elementType: "geometry",
                stylers: [
                    {
                        color: "#212121"
                    }
                ]
            },
            {
                elementType: "labels.icon",
                stylers: [
                    {
                        visibility: "off"
                    }
                ]
            },
            {
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#757575"
                    }
                ]
            },
            {
                elementType: "labels.text.stroke",
                stylers: [
                    {
                        color: "#212121"
                    }
                ]
            },
            {
                featureType: "administrative",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#757575"
                    }
                ]
            },
            {
                featureType: "administrative.country",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#9e9e9e"
                    }
                ]
            },
            {
                featureType: "administrative.land_parcel",
                stylers: [
                    {
                        visibility: "off"
                    }
                ]
            },
            {
                featureType: "administrative.locality",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#bdbdbd"
                    }
                ]
            },
            {
                featureType: "poi",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#757575"
                    }
                ]
            },
            {
                featureType: "poi.park",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#181818"
                    }
                ]
            },
            {
                featureType: "poi.park",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#616161"
                    }
                ]
            },
            {
                featureType: "poi.park",
                elementType: "labels.text.stroke",
                stylers: [
                    {
                        color: "#1b1b1b"
                    }
                ]
            },
            {
                featureType: "road",
                elementType: "geometry.fill",
                stylers: [
                    {
                        color: "#2c2c2c"
                    }
                ]
            },
            {
                featureType: "road",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#8a8a8a"
                    }
                ]
            },
            {
                featureType: "road.arterial",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#373737"
                    }
                ]
            },
            {
                featureType: "road.highway",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#3c3c3c"
                    }
                ]
            },
            {
                featureType: "road.highway.controlled_access",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#4e4e4e"
                    }
                ]
            },
            {
                featureType: "road.local",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#616161"
                    }
                ]
            },
            {
                featureType: "transit",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#757575"
                    }
                ]
            },
            {
                featureType: "water",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#000000"
                    }
                ]
            },
            {
                featureType: "water",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#3d3d3d"
                    }
                ]
            }
        ],

        light:
        [
            {
                elementType: "geometry",
                stylers: [
                    {
                        color: "#f0f3f8"
                    }
                ]
            },
            {
                elementType: "labels.icon",
                stylers: [
                    {
                        visibility: "off"
                    }
                ]
            },
            {
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#616161"
                    }
                ]
            },
            {
                elementType: "labels.text.stroke",
                stylers: [
                    {
                        color: "#f0f3f8"
                    }
                ]
            },
            {
                featureType: "administrative.land_parcel",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#bdbdbd"
                    }
                ]
            },
            {
                featureType: "poi",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#eeeeee"
                    }
                ]
            },
            {
                featureType: "poi",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#757575"
                    }
                ]
            },
            {
                featureType: "poi.park",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#e5e5e5"
                    }
                ]
            },
            {
                featureType: "poi.park",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#9e9e9e"
                    }
                ]
            },
            {
                featureType: "road",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#ffffff"
                    }
                ]
            },
            {
                featureType: "road.arterial",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#757575"
                    }
                ]
            },
            {
                featureType: "road.highway",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#dadada"
                    }
                ]
            },
            {
                featureType: "road.highway",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#616161"
                    }
                ]
            },
            {
                featureType: "road.local",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#9e9e9e"
                    }
                ]
            },
            {
                featureType: "transit.line",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#e5e5e5"
                    }
                ]
            },
            {
                featureType: "transit.station",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#eeeeee"
                    }
                ]
            },
            {
                featureType: "water",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#c9c9c9"
                    }
                ]
            },
            {
                featureType: "water",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#9e9e9e"
                    }
                ]
            }
        ]
    },

    chart_colors: ["#f13675", "#e97fb6", "#ac67cf", "#517bec", "#1cb0e3", "#00cbd0", "#4a5288", "#232b37", "#232b37", "#f68a1c", "#db5d1f", "#f13675", "#e97fb6"],

    categories: [
        {
            title: "common.allBooks",
            key: "all"
        },
        {
            title: "common.fiction",
            key: "fiction"
        },
        {
            title: "common.nonfiction",
            key: "nonfiction"
        },
        {
            title: "common.youngAdult",
            key: "young_adult"
        },
        {
            title: "common.children",
            key: "children"
        },
        {
            title: "common.graphicNovelsAndComics",
            key: "graphic_novels_comics"
        },
        {
            title: "common.references",
            key: "references"
        },
        {
            title: "common.miscellaneous",
            key: "miscellaneous"
        }
    ],

    interceptor:
    {
        token: [1017]
    },
    date_range:
    [
        {
            label_ar: "اسبوع",
            label_en: "Week",
            value: "week"
        },
        {
            label_ar: "شهر",
            label_en: "Month",
            value: "month"
        },
        {
            label_ar: "ربع سنوي",
            label_en: "Quarter",
            value: "quarter"
        },
        {
            label_ar: "سنة",
            label_en: "Year",
            value: "year"
        }
    ],
    user_role:
    [
        {
            label_ar: "مالك",
            label_en: "Owner",
            value: "owner"
        },
        {
            label_ar: "محرر",
            label_en: "Editor",
            value: "maintainer"
        },
        {
            label_ar: "مشاهد",
            label_en: "Viewer",
            value: "observer"
        }
    ],
    announcementFlags: ["webhook_is_missing", "cr_is_missing", "cr_is_expired", "subscription_has_expired"]
};

export default constant;