import React, { useState } from "react";

import LineMsg from "./lineMsg";
import Label from "./label";
import Input from "./input";

import { strings } from "../../util/formatter";
import { validateLettersWithSpaces } from "../../util/validator";

const CreateDirectoryStep2 = ({ createDirectoryForm, setCreateDirectoryForm }) =>
{
    const {
        entityName
    } = createDirectoryForm || {};

    const onEntityNameChange = (name) =>
    {
        setCreateDirectoryForm({ ...createDirectoryForm, entityName: name });
    };

    return (
        <>
            <div className="w-full pb-grid-sm">
                <Label label={strings("label.entityNameInCR")}/>
                <Input
                    name="entityName"
                    value={entityName}
                    setValue={onEntityNameChange}
                />
                <LineMsg msg={validateLettersWithSpaces(entityName, undefined, true)?.msg} type="error"/>
            </div>
        </>
    );
};

export default CreateDirectoryStep2;