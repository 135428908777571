import React, { createRef, useContext, useState } from "react";

import LineMsg from "./lineMsg";
import Label from "./label";
import Input from "./input";
import DropdownMenu from "./dropdownMenu";
import DatePickerButton from "./datePickerButton";
import FileInput from "./fileInput";

import { getDate, strings } from "../../util/formatter";
import { validateFileType, validateFutureDate, validateNumbersOnly } from "../../util/validator";
import { SessionContext } from "../../util/context";

const CreateProducerLicense = ({ producerLicenseForm, setProducerLicenseForm }) =>
{
    const { prefs } = useContext(SessionContext);

    const plFileRef = createRef(null);
    const {
        plInfo
    } = producerLicenseForm || {};

    const {
        haveProducerLicense,
        plNumber,
        selectedPLCountry,
        plExpiryDate,
        plFile
    } = plInfo || {};

    const PL_ISSUED_COUNTRY = [
        {
            id: 1,
            name: strings("country.sa"),
            country_code: "sa"
        }
    ];

    const PL_OPTIONS = [
        {
            label: strings("common.no"),
            value: false
        },
        {
            label: strings("common.yes"),
            value: true
        }
    ];

    const selectPLFile = () =>
    {
        if (plFileRef.current !== null)
        {
            plFileRef.current.click();
        }
    };

    const setPLForm = (value, key) =>
    {
        setProducerLicenseForm({ ...producerLicenseForm, plInfo: { ...plInfo, [key]: value } });
    };

    return (
        <>
            <div className="w-full">
                <Label label={strings("label.haveProducerLicense")}/>
                <DropdownMenu
                    useLocalization={true}
                    list={PL_OPTIONS}
                    labelKey="label"
                    selectedValue={haveProducerLicense}
                    setSelected={(e) => { setPLForm(e, "haveProducerLicense") }}
                />
                <LineMsg type="error"/>
            </div>
            {haveProducerLicense?.value &&
                <>
                    <div className="pb-grid-sm">
                        <Label label={strings("label.producerLicenseNumber")}/>
                        <Input
                            name="producerLicenseNumber"
                            value={plNumber}
                            setValue={(e) => { setPLForm(e, "plNumber") }}
                        />
                        <LineMsg msg={validateNumbersOnly(plNumber)?.msg} type="error"/>
                    </div>
                    <div className="flex justify-between gap-grid-xl">
                        <div className="w-full pb-grid-sm">
                            <Label label={strings("label.producerLicenseIssuedCountry")}/>
                            <DropdownMenu
                                list={PL_ISSUED_COUNTRY}
                                labelKey="name"
                                placeholder={strings("placeholder.selectCountry")}
                                selectedValue={selectedPLCountry}
                                setSelected={(e) => { setPLForm(e, "selectedPLCountry") }}
                            />
                            <LineMsg type="error"/>
                        </div>
                        <div className="w-full pb-grid-sm">
                            <Label label={strings("label.producerLicenseExpiryDate")}/>
                            <DatePickerButton
                                value={plExpiryDate}
                                setValue={(e) => { setPLForm(e, "plExpiryDate") }}
                            />
                        <LineMsg msg={validateFutureDate(getDate(prefs?.lang, plExpiryDate, "YYYY-MM-DD"))?.msg} type="error"/>
                        </div>
                    </div>
                    <div className="pb-grid-sm">
                        <Label label={strings("label.producerLicenseFile")}/>
                        <FileInput
                            fileRef={plFileRef}
                            value={plFile}
                            setValue={(e) => { setPLForm(e, "plFile") }}
                            selectFile={selectPLFile}
                            acceptedTypes=".pdf"
                        />
                        <LineMsg msg={validateFileType(plFile, ["application/pdf"], ["pdf"])?.msg} type="error"/>
                    </div>
                </>
            }
        </>
    );
};

export default CreateProducerLicense;