import React, { useContext } from "react";

import { SessionContext } from "../../util/context";
import { AnimatePresence, motion } from "framer-motion";

import { getLangCountryURL, strings } from "../../util/formatter";
import { useNavigate } from "react-router-dom";

const DismissibleBanner = () =>
{
    const { prefs, shouldShowBnr, setShouldShowBnr, dismissableBnr, setDismissableBnr, setActiveModal, setModalOptions } = useContext(SessionContext);
    const navigate = useNavigate();

    const action = () =>
    {
        if (dismissableBnr?.distination)
        {
            if (dismissableBnr?.destinationType === "modal")
            {
                setModalOptions(dismissableBnr?.options);
                setActiveModal(dismissableBnr?.distination);
            }
            else
            {
                navigate(`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/${dismissableBnr?.distination}`);
            }
        }
    }

    return (
        <AnimatePresence>
            {shouldShowBnr &&
                <motion.div
                    initial={{ height: 0 }}
                    animate={{ height: 40 }}
                    exit={{ height: 0 }}
                    transition={{ duration: 0.6 }}
                    className={`absolute top-0 left-0 flex items-center justify-between z-[51] w-full ${dismissableBnr?.type === "msg" ? "bg-accent-regular" : dismissableBnr?.type === "danger" ? "bg-status-danger-lightest" : "bg-status-warning-lightest"}`}
                >
                    <motion.div
                        initial={{ translateY: -40 }}
                        animate={{ translateY: 0 }}
                        exit={{ translateY: -40 }}
                        transition={{ duration: 0.6 }}
                        className="w-[32px]"
                    />
                    <motion.div
                        initial={{ translateY: -40 }}
                        animate={{ translateY: 0 }}
                        exit={{ translateY: -40 }}
                        transition={{ duration: 0.6 }}
                        className={`font-primary ${dismissableBnr?.type === "msg" || dismissableBnr?.type === "danger" ? "text-primary-lightest" : ""}`}
                    >
                        <motion.div
                            initial={{ translateY: -40 }}
                            animate={{ translateY: 0 }}
                            exit={{ translateY: -40 }}
                            transition={{ duration: 0.6 }}
                        >
                            {dismissableBnr?.msg}
                            {dismissableBnr?.type !== "msg" &&
                            <motion.span
                                initial={{ translateY: -40 }}
                                animate={{ translateY: 0 }}
                                exit={{ translateY: -40 }}
                                transition={{ duration: 0.6 }}
                                className={`ps-grid-xs underline cursor-pointer hover:opacity-70 transition-opacity duration-too-fast ease-in`}
                                onClick={action}
                            >
                                {strings("button.byClickingHere")}
                            </motion.span>
                        }
                        </motion.div>
                    </motion.div>
                    <motion.div
                        initial={{ translateY: -40, paddingBottom: "0" }}
                        animate={{ translateY: 0, paddingBottom: "0.25rem" }}
                        exit={{ translateY: -40, paddingBottom: "0" }}
                        transition={{ duration: 0.6 }}
                        className={`text-center w-[32px] text-[18px] ${dismissableBnr?.type === "msg" || dismissableBnr?.type === "danger" ? "text-primary-lightest" : ""} hover:opacity-70 transition-opacity duration-too-fast ease-in cursor-pointer`}
                        onClick={() => {
                            setShouldShowBnr(false);
                            setDismissableBnr({});
                        }}
                    >
                        &times;
                    </motion.div>
                </motion.div>
        }
        </AnimatePresence>
    );
};

export default DismissibleBanner;