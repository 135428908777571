import React, { useContext } from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";

import { SessionContext } from "../../util/context";
import constant from "../../config/constant";

const LocaleWrapper = () =>
{
    const { prefs } = useContext(SessionContext);
    const { locale } = useParams();

    if (!constant.validLocales.includes(locale))
    {
        return <Navigate to={`/${prefs?.lang}-${prefs?.country_code}/error/404`} replace />;
    }

    return <Outlet />;
};

export default LocaleWrapper;