import React, { useContext, useEffect, useState } from "react";

import Section from "../view/component/section";
import ProductCard from "../view/component/productCard";
import ShowAll from "../view/component/showAll";

import { SessionContext } from "../util/context";
import { getDateISO, getLangCountryURL, strings } from "../util/formatter";

import book1 from "../assets/image/books/book1.jpg"
import book2 from "../assets/image/books/book2.jpg"
import book3 from "../assets/image/books/book3.jpg"
import book4 from "../assets/image/books/book4.jpg"
import book5 from "../assets/image/books/book5.jpg"
import book6 from "../assets/image/books/book6.jpeg"
import book7 from "../assets/image/books/book7.jpg"
import book8 from "../assets/image/books/book8.jpg"
import { request } from "../util/http";
import endpoint from "../config/endpoint";
import Loader from "../view/component/loader";

const NewArrival = () =>
{
    const { prefs } = useContext(SessionContext);
    const [isLoading, setIsLoading] = useState(false);
    const [fictionProductList, setFictionProductList] = useState([]);
    const [poetryProductList, setPoetryProductList] = useState([]);
    const NEWLY_ITEMS = {
        fiction: [
            {
                id: 1,
                cover: book1,
                name: "شقة الحورية",
                contributor_name: "غازي القصيبي"

            },
            {
                id: 2,
                cover: book2,
                name: "جنوب الحدود غرب الشمس",
                contributor_name: "هاروكي موراكامي"
            },
            {
                id: 3,
                cover: book3,
                name: "ثلاثية غرناطة",
                contributor_name: "رضوى عاشور"
            },
            {
                id: 4,
                cover: book4,
                name: "قريب من البحر بعيد عن الزرقة",
                contributor_name: "جاسم الصحيح"
            }
        ],
        poetry: [
            {
                id: 5,
                cover: book5,
                name: "مزرعة الحيوان",
                contributor_name: "جورج أورويل"

            },
            {
                id: 6,
                cover: book6,
                name: "السعودية المتغيرة",
                contributor_name: "شون فولي"
            },
            {
                id: 7,
                cover: book7,
                name: "الحرب و السلم",
                contributor_name: "ليف تولستوي"
            },
            {
                id: 8,
                cover: book8,
                name: "الحالة الحرجة للمدعو ك",
                contributor_name: "عزيز محمد"
            }
        ]
    };

    const fetchFictionProductList = async () =>
    {
        setIsLoading(true);
        try
        {
            const res = await request("GET", `${endpoint.bms.product.list}?page=1&timestamp=${getDateISO()}&sort_type=produced_at&subject_code=fic000000`);
            setFictionProductList(res?.list);
            setIsLoading(false);
        }
        catch (error) {
            setIsLoading(false);
        }
    };

    const fetchPoetryProductList = async () =>
    {
        setIsLoading(true);
        try
        {
            const res = await request("GET", `${endpoint.bms.product.list}?page=1&timestamp=${getDateISO()}&sort_type=produced_at&subject_code=poe000000`);
            setPoetryProductList(res?.list);
            setIsLoading(false);
        }
        catch (error) {
            setIsLoading(false);
        }
    };

    useEffect(() =>
    {
        fetchFictionProductList();
        fetchPoetryProductList();
    }, []);

    return (
        <Section optionalStyle="min-h-[calc(100vh-474px)]">
            {isLoading && <Loader />}
            <div className="relative pt-[60%] sm:pt-[40%] md:pt-[35%] lg:pt-[20%] pb-grid-xl md:px-0">
                <div dir={prefs?.dir}>
                    <div className="flex justify-between gap-grid-sm">
                        <p className="text-xl font-primary text-primary-lightest">
                            {strings(`common.fiction`)}
                        </p>
                        <ShowAll route={`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/catalog/subject/fic000000`}/>
                    </div>
                    {fictionProductList?.length > 0
                    ? <div className="flex justify-between gap-grid-sm">
                        {fictionProductList?.slice(0, 4)?.map((item, indexOfItem) => (
                            <ProductCard
                                key={indexOfItem}
                                item={item}
                                containerClassName="min-w-[218px] min-h-[410px] max-w-[218px] max-h-[410px] mt-grid-md mb-[115px] p-grid-lg flex flex-col justify-between items-center bg-primary-lightest rounded-sm cursor-pointer shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)]"
                                imgClassName="w-[218px] h-[270px]"
                                textClassName="pt-grid-xs text-center"
                            />
                        ))}
                    </div>
                    : <div>
                        <p className="mt-grid-xl mb-[180px] text-primary-lightest text-center">{strings("empty.newArrival")}</p>
                    </div>
                    }
                </div>
                <div dir={prefs?.dir}>
                    <div className="flex justify-between gap-grid-sm">
                        <p className="text-xl font-primary text-primary-lightest">
                            {strings(`common.poetry`)}
                        </p>
                        <ShowAll route={`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/catalog/subject/poe000000`}/>
                    </div>
                    {fictionProductList?.length > 0
                    ? <div className="flex justify-between gap-grid-sm">
                        {poetryProductList?.slice(0, 4)?.map((item, indexOfItem) => (
                            <ProductCard
                                key={indexOfItem}
                                item={item}
                                containerClassName="min-w-[218px] min-h-[410px] max-w-[218px] max-h-[410px] mt-grid-md mb-[115px] p-grid-lg flex flex-col justify-between items-center bg-primary-lightest rounded-sm cursor-pointer shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)]"
                                imgClassName="w-[218px] h-[270px]"
                                textClassName="pt-grid-xs text-center"
                            />
                        ))}
                    </div>
                    : <div>
                        <p className="mt-grid-xl mb-[180px] text-primary-lightest text-center">{strings("empty.newArrival")}</p>
                    </div>
                    }
                </div>
            </div>
        </Section>
    );
};

export default NewArrival;