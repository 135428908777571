import React from "react";

import { strings } from "../../util/formatter";

const ManageWalletRequests = ({ balanceHistory }) =>
{
    return (
        <>
            {balanceHistory?.list?.length === 0
            ? <p className="py-grid-lg text-center">
                    {strings("empty.balanceHistory")}
                </p>
            : balanceHistory?.list?.map((item, index) =>
                (
                    <div key={index} />
                ))
            }
        </>
    );
};

export default ManageWalletRequests;