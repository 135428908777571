import React, { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Lenis from "@studio-freight/lenis";

import constant from "../config/constant";
import LocalStorage from "./localStorage";
import { handleSEOURL } from "./formatter";

const storageBus = (() =>
{
    const listeners = new Set();

    return {
        subscribe: (callback) =>
        {
            listeners.add(callback);
            return () => listeners.delete(callback);
        },
        notify: (data) =>
        {
            listeners.forEach((callback) => callback(data));
        }
    };
})();

export const SessionContext = createContext({});

const GlobalContext = ({ children }) =>
{
    const { init, set, unset, destroy } = LocalStorage;

    // persist context states
    const [prefs, setPrefs] = useState(() =>
    {
        const storedPrefs = localStorage.getItem("prefs");
        return storedPrefs && storedPrefs.trim() !== "" ? JSON.parse(storedPrefs) : {};
    });
    const [userInfo, setUserInfo] = useState(() =>
    {
        const storedUserInfo = localStorage.getItem("user_info");
        return storedUserInfo && storedUserInfo.trim() !== "" ? JSON.parse(storedUserInfo) : {};
    });
    const [merchantInfo, setMerchantInfo] = useState(() =>
    {
        const storedMerchantInfo = localStorage.getItem("merchant_info");
        return storedMerchantInfo && storedMerchantInfo.trim() !== "" ? JSON.parse(storedMerchantInfo) : {};
    });
    const [accessToken, setAccessToken] = useState(() =>
    {
        const storedAccessToken = localStorage.getItem("access_token");
        return storedAccessToken && storedAccessToken.trim() !== "" ? storedAccessToken : "";
    });
    const [refreshToken, setRefreshToken] = useState(() =>
    {
        const storedRefreshToken = localStorage.getItem("refresh_token");
        return storedRefreshToken && storedRefreshToken.trim() !== "" ? storedRefreshToken : "";
    });
    const [roleCode, setRoleCode] = useState(() =>
    {
        const storedRoleCode = localStorage.getItem("role_code");
        return storedRoleCode && storedRoleCode.trim() !== "" ? storedRoleCode : "";
    });
    const [walletId, setWalletId] = useState(() =>
    {
        const storedWalletId = localStorage.getItem("wallet_id");
        return storedWalletId && storedWalletId.trim() !== "" ? storedWalletId : "";
    });
    const [announcements, setAnnouncements] = useState(() =>
    {
        const storedAnnouncements = localStorage.getItem("announcements");
        return storedAnnouncements && storedAnnouncements.trim() !== "" ? JSON.parse(storedAnnouncements) : [];
    });

    // other states
    const [userPersona, setUserPersona] = useState(undefined);
    const [activeModal, setActiveModal] = useState(undefined);
    const [modalOptions, setModalOptions] = useState({});
    const [showSearchInputInHeader, setShowSearchInputInHeader] = useState(true);
    const [shouldShowBnr, setShouldShowBnr] = useState(false);
    const [dismissableBnr, setDismissableBnr] = useState({
        msg: null,
        type: null,
        options: {},
        distination: null,
        distinationType: null
    });
    const [animateOpacity, setAnimateOpacity] = useState("");
    const [lenis] = useState(new Lenis());
    const location = useLocation();
    const isPostLogin = location.pathname?.includes("dashboard");
    const navigate = useNavigate();

    const actions =
    {
        prefs: () =>
            setPrefs(
                localStorage.getItem("prefs") && localStorage.getItem("prefs")?.trim() !== ""
                    ? { ...JSON.parse(localStorage.getItem("prefs")) }
                    : {}
            ),
        access_token: (value) => setAccessToken(value),
        refresh_token: (value) => setRefreshToken(value),
        user_info: () =>
            setUserInfo(
                localStorage.getItem("user_info") && localStorage.getItem("user_info")?.trim() !== ""
                    ? { ...JSON.parse(localStorage.getItem("user_info")) }
                    : {}
            ),
        merchant_info: () =>
            setMerchantInfo(
                localStorage.getItem("merchant_info") && localStorage.getItem("merchant_info")?.trim() !== ""
                    ? { ...JSON.parse(localStorage.getItem("merchant_info")) }
                    : {}
            ),
        role_code: (value) => setRoleCode(value),
        wallet_id: (value) => setWalletId(value),
        announcements: () =>
            setAnnouncements(
                localStorage.getItem("announcements") && localStorage.getItem("announcements")?.trim() !== ""
                    ? JSON.parse(localStorage.getItem("announcements"))
                    : []
            )
    };

    const setter = async (key, value, prefsKey = undefined) =>
    {
        await set(key, value, prefsKey);
        await handleKeyUpdate(key, value);
        storageBus.notify({ key, value });
    };

    const handleKeyUpdate = async (key, value) =>
    {
        if (actions[key])
        {
            actions[key](value);
        }
    };

    const remover = async (key = "all") =>
    {
        if (key === "all")
        {
            await destroy();
        }
        else
        {
            await unset(key);
        }
        setPrefs({});
        storageBus.notify({ key, value: {} });
    };

    const setLenis = () =>
    {
        function raf (time)
        {
            lenis.raf(time);
            requestAnimationFrame(raf);
        }

        requestAnimationFrame(raf);
    };

    const initiatStorage = async () =>
    {
        if (!prefs.theme && !prefs.lang && !prefs.country_code)
        {
            await init();
            setPrefs(localStorage.getItem("prefs") && localStorage.getItem("prefs")?.trim() !== ""
            ? { ...JSON.parse(localStorage.getItem("prefs")) }
            : {});
        }
    }

    useEffect(() =>
    {
        const handleLangCountryChanges = async () =>
        {
            if (prefs?.lang)
            {
                const pathnameList = location?.pathname?.split("/");
                const localizationParams = pathnameList[1]?.split("-");
                const setLangAndCountry = async () => {
                    if (localizationParams?.[0] && localizationParams?.[1])
                    {
                        await setter("prefs", { ...prefs, lang: localizationParams[0], country_code: localizationParams[1] }, "lang");
                    }
                    else
                    {
                        await setter("prefs", { ...prefs, lang: constant.lang.values?.[0], country_code: constant.country.values?.[0] }, "lang");
                    }
                };
                if (constant.lang.values?.includes(localizationParams[0]) && constant.country.values?.includes(localizationParams[1]))
                {
                    await setLangAndCountry();
                }
                else if (location?.pathname === "/")
                {
                    await setLangAndCountry();
                    navigate(handleSEOURL(`${location?.pathname}${location?.search ? location?.search : ""}`, constant.lang.values?.[0], constant.country.values?.[0]));
                }
            }
        };

        handleLangCountryChanges();
    }, [prefs.country_code, prefs.lang]);

    useEffect(() =>
    {
        if (!prefs.theme && !prefs.lang && !prefs.country_code) initiatStorage();

        setLenis();

        const unsubscribe = storageBus.subscribe(({ key, value }) => handleKeyUpdate(key, value));

        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <SessionContext.Provider
            value={{
                setter,
                remover,
                prefs,
                userPersona,
                setUserPersona,
                accessToken,
                refreshToken,
                userInfo,
                merchantInfo,
                roleCode,
                walletId,
                announcements,
                lenis,
                activeModal,
                setActiveModal,
                modalOptions,
                setModalOptions,
                showSearchInputInHeader,
                setShowSearchInputInHeader,
                isPostLogin,
                shouldShowBnr,
                setShouldShowBnr,
                dismissableBnr,
                setDismissableBnr,
                animateOpacity,
                setAnimateOpacity
            }}
        >
            {children}
        </SessionContext.Provider>
    );
};

export default GlobalContext;
