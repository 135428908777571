import React, { useContext, useEffect, useState } from "react";

import SVGIcon from "./svgIcons";

import { SessionContext } from "../../util/context";

const FileInput = (props) =>
{
    const { isPostLogin } = useContext(SessionContext);
    const { fileRef, value, setValue, selectFile, acceptedTypes = ".jpg,.jpeg,.png" } = props;

    return (
        <div onClick={selectFile} className={`w-full px-grid-sm flex justify-between items-center gap-grid-xxl text-sm ${isPostLogin ? "py-[14px] text-neutral-low-darkest bg-neutral-high-regular border-2 border-neutral-high-regular" : "py-grid-sm bg-secondary-light text-secondary-darkest"} cursor-pointer rounded-xs`}>
            <div>
                {value?.name || ""}
            </div>
            <SVGIcon name="upload" viewBox="0 0 24 24" width={20} height={20} stroke={isPostLogin ? "stroke-neutral-low-darkest" : "stroke-secondary-darkest"} className="fill-none"/>
            <input
                hidden
                ref={fileRef}
                onChange={(e) => setValue(e.target.files[0])}
                type="file"
                accept={acceptedTypes}
            />
        </div>
    );
};

export default FileInput;