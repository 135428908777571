/* eslint-disable no-constant-condition */
import React, { useContext, useEffect, useLayoutEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { HelmetProvider, Helmet } from "react-helmet-async";
import { AnimatePresence, motion } from "framer-motion";

import Header from "../component/header";
import Footer from "../component/footer";
import NoInternetConnection from "../component/noInternetConnection";

import { SessionContext } from "../../util/context";
import constant from "../../config/constant";
import checkNetworkConnection from "../../hook/checkNetworkConnection";

const Layout = () =>
{
    const { prefs, isPostLogin, shouldShowBnr } = useContext(SessionContext);
    const location = useLocation();
    const isOnline = checkNetworkConnection();

    useLayoutEffect(() =>
    {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <AnimatePresence>
            <motion.div
                initial={{ marginTop: 0 }}
                animate={{ marginTop: shouldShowBnr ? 40 : 0 }}
                exit={{ marginTop: 0 }}
                transition={{ duration: 0.6 }}
                className={`w-full font-secondary`}
            >
                <HelmetProvider>
                    <Helmet>
                        <html lang={prefs?.lang} data-theme={prefs?.theme} dir={prefs?.dir}/>
                    </Helmet>
                    <>
                        {isOnline
                        ? <>
                                {!isPostLogin
                                ? <>
                                        <Header />
                                        <div className="pt-[70px]">
                                            <Outlet/>
                                        </div>
                                        {!constant?.hide_footer?.some(path => location.pathname.includes(path)) &&
                                            <Footer />
                                        }
                                    </>
                                : <Outlet/>
                                }
                            </>
                        : <NoInternetConnection />
                        }
                    </>
                </HelmetProvider>
            </motion.div>
        </AnimatePresence>
    );
};

export default Layout;