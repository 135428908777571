import React, { useContext, useState } from "react";

import Button from "./button";

import { SessionContext } from "../../util/context";
import { getLangCountryURL, strings } from "../../util/formatter";
import { createSearchParams, useNavigate } from "react-router-dom";

const SearchInput = ({ shouldShowShadow = true }) =>
{
    const navigate = useNavigate();
    const { prefs, isPostLogin } = useContext(SessionContext);
    const [searchKey, setSearchKey] = useState("");

    const handleClick = () =>
    {
        if (searchKey)
        {
            setSearchKey("");
            navigate({
                pathname: `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/search`,
                search: createSearchParams({
                    q: searchKey
                })?.toString()
            });
        }
    };

    return (
        <div className="relative flex justify-center items-center">
            <input
                name="search"
                value={searchKey}
                placeholder={strings("placeholder.search")}
                onChange={(e) => setSearchKey(e.target.value)}
                className={`w-full px-grid-md ${isPostLogin ? "py-[10.5px] bg-neutral-high-regular text-neutral-low-darkest placeholder:text-neutral-low-darkest/50 border-2 border-transparent focus:border-primary-regular" : "py-grid-sm bg-primary-lightest text-secondary-darkest"} text-md rounded-full focus:outline-none transition-colors duration-fast ease-in ${shouldShowShadow ? "shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)]" : ""}`}
            />
            <Button
                text={strings("button.searchNow")}
                iconOnly={isPostLogin}
                icon={isPostLogin ? "magnifer" : "arrow"}
                iconWidth={isPostLogin ? 17 : 24}
                iconHeight={isPostLogin ? 16 : 24}
                viewBox={isPostLogin ? "0 0 24 24" : "0 0 0 0"}
                bgColor={isPostLogin ? "bg-transparent" : "bg-secondary-darkest"}
                font="text-md"
                padding={`py-grid-xs ${isPostLogin ? "px-grid-xs" : "pe-grid-xs ps-grid-sm"}`}
                iconStyle={`${prefs.dir === "rtl" ? "" : "rotate-180"} fill-none`}
                iconStroke={isPostLogin ? "stroke-neutral-low-regular" : "stroke-primary-lightest"}
                shouldHover={false}
                optionalStyle="absolute my-grid-sm end-grid-xs flex items-center gap-grid-xs sm:gap-grid-md hover:opacity-70 transition-opacity duration-fast"
                onClick={handleClick}
            />
        </div>
    );
};

export default SearchInput;