import React, { useContext, useEffect, useRef, useState } from "react";

import { AnimatePresence, easeInOut, motion } from "framer-motion";

import SVGIcon from "./svgIcons";
import CachedImg from "./cachedImg";

import { SessionContext } from "../../util/context";
import { priceToPriceWithCurrency, strings } from "../../util/formatter";

export const USER_MENU = [
    {
        value: "myAccount",
        label: "common.myAccount"
    },
    {
        value: "manageEntity",
        label: "common.manageEntity"
    },
    {
        value: "backToMyEntities",
        label: "common.backToMyEntities"
    },
    {
        value: "walletBalance",
        label: "common.walletBalance"
    },
    {
        value: "logout",
        label: "common.logout"
    }
];

const UserDropdown = (props) =>
{
    const { prefs, userInfo, merchantInfo, isPostLogin } = useContext(SessionContext);
    const {
        walletData,
        setSelected
    } = props;
    const [isOpen, setIsOpen] = useState(false);
    const target = useRef(null);

    useEffect(() =>
    {
        document.addEventListener("click", clickOutside, true);
        return () =>
        {
            document.removeEventListener("click", clickOutside, true);
        };
    }, []);

    const open = () =>
    {
        setIsOpen(true);
    };

    const select = (item) =>
    {
        setSelected(item);
        setIsOpen(false);
    };

    const clickOutside = (e) =>
    {
        if (!target.current?.contains(e.target)) setIsOpen(false);
    };

    return (
        <div className="relative">
            <div onClick={open} className={`w-full h-[40px] ps-grid-xs pe-grid-xs md:pe-grid-sm flex justify-between items-center gap-grid-xs bg-neutral-high-regular text-neutral-low-dark transition-colors duration-fast ease-in rounded-md cursor-pointer`}>
                <div className="flex md:gap-grid-xs items-center">
                    {userInfo?.avatar_img && <CachedImg src={userInfo?.avatar_img} alt className="max-w-[28px] max-h-[28px] rounded-[100%]"/>}
                    {!userInfo?.avatar_img && <div className="flex justify-center items-center w-[28px] h-[28px] bg-neutral-high-dark text-neutral-low-darkest transition-colors duration-fast ease-in text-sm rounded-[100%]">{userInfo?.email ? Array.from(userInfo?.email)[0] : ""}</div>}
                    <div className="hidden md:flex flex-col">
                        <p className="font-primary text-sm">
                            {userInfo?.email}
                        </p>
                        <p className="text-xs text-neutral-low-regular transition-colors duration-fast ease-in">
                            {merchantInfo?.display_name}
                        </p>
                    </div>
                </div>
                <SVGIcon name="chevron" viewBox="0 0 24 24" width={20} height={20} stroke="stroke-neutral-low-regular" className="rotate-90 fill-none"/>
            </div>
            <AnimatePresence>
                {isOpen &&
                    <motion.div
                        ref={target}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3, ease: easeInOut }}
                        className={`absolute z-10 top-12 start-0 sm:start-auto end-0 w-[180px] sm:w-[300px] h-auto rounded-xs drop-shadow ${isPostLogin ? "border border-neutral-high-regular bg-neutral-high-lightest text-neutral-low-darkest" : "bg-secondary-lightest text-secondary-darkest"}`}
                    >
                        <ul data-lenis-prevent className="max-h-[254px] overflow-scroll rounded-xs divide-y divide-solid divide-neutral-high-regular">
                            {USER_MENU.map((item, index) =>
                                (
                                    <div
                                        key={index}
                                        className={`px-grid-xs py-grid-sm flex justify-start items-start gap-grid-xs text-sm text-start cursor-pointer ${item.value === "logout" && "text-status-danger-regular"} ${isPostLogin ? "hover:bg-neutral-high-light" : "hover:bg-secondary-light"} hover:transition-colors hover:duration-fast hover:ease-linear`}
                                        onClick={() => select(item)}
                                    >
                                        {item.value === "walletBalance"
                                        ? strings(item.label)?.replace("{BALANCE}", priceToPriceWithCurrency(prefs?.currency, walletData?.balance ? walletData?.balance : 0))
                                        : strings(item.label)
                                        }
                                    </div>
                                ))}
                        </ul>
                    </motion.div>
                }
            </AnimatePresence>
        </div>
    );
};

export default UserDropdown;