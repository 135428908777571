import React, { useContext, useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import SearchInput from "./searchInput";
import Button from "./button";
import UserDropdown from "./userDropdown";
import Loader from "./loader";
import SVGIcon from "./svgIcons";
import CachedImg from "./cachedImg";
import SubMenu from "./subMenu";

import { SessionContext } from "../../util/context";
import { getLangCountryURL, strings } from "../../util/formatter";
import constant from "../../config/constant";

import LOGO_LIGHT from "../../assets/image/logo_light.png";
import LOGO_DARK from "../../assets/image/logo_dark.png";

const PostLoginHeader = (props) =>
{
    const { prefs, announcements, setter, setActiveModal, setModalOptions } = useContext(SessionContext);
    const navigate = useNavigate();
    const { walletData, shouldShowMenu, toggleMenu } = props;
    const [selected] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() =>
    {
        if (Object.keys(selected)?.length > 0)
        {
            setIsLoading(true);
            setTimeout(() =>
            {
                setIsLoading(false);
                navigate(`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/${selected?.path}`);
            }, 1500);
        }
    }, [selected]);

    const handleUserDropdownClick = (item) =>
    {
        if (item.value === "myAccount")
        {
            setActiveModal("userAccount");
            setModalOptions({
                title: strings("common.myAccount")
            });
        }

        if (item.value === "manageEntity")
        {
            setActiveModal("manageEntity");
            setModalOptions({
                title: strings("common.manageEntity")
            });
        }

        if (item.value === "backToMyEntities")
        {
            setIsLoading(true);
            setTimeout(() =>
            {
                setIsLoading(false);
                navigate(`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/directory`);
            }, 1500);
        }

        if (item.value === "walletBalance")
        {
            setActiveModal("manageWallet");
            setModalOptions({
                title: strings("title.manageWallet"),
                walletData: walletData
            });
        }

        if (item.value === "logout")
        {
            setIsLoading(true);
            setTimeout(() =>
            {
                setIsLoading(false);
                navigate(`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}`);
            }, 1500);
        }
    };

    const handleNotificationsClick = () =>
    {
        setActiveModal("notification");
        setModalOptions({ title: strings("title.notifications") });
    };

    return (
        <div className={`w-full sticky top-0 py-grid-md px-grid-md bg-neutral-high-lightest z-50 transition-all duration-fast ease-in text-primary-dark border-b border-neutral-high-regular`}>
            {isLoading && <Loader />}
            <div className="w-full">
                <div className={`w-full flex lg:hidden justify-between items-center pb-grid-md`}>
                    <Link
                        to={`${getLangCountryURL(prefs?.lang, prefs?.country_code)}`}
                    >
                        <CachedImg src={prefs?.theme === constant?.theme?.values?.[0] ? LOGO_DARK : LOGO_LIGHT} alt={strings("mofahres")} className="w-[120px]"/>
                    </Link>
                    <div className="cursor-pointer" onClick={toggleMenu}>
                        <SVGIcon name="hamburger" className="fill-neutral-low-regular" width={30} height={30} />
                    </div>
                </div>
            </div>
            <div className="w-full flex flex-col-reverse sm:flex-row justify-between items-start sm:items-center gap-grid-sm">
                <div className="w-full md:w-[45%]">
                    <SearchInput theme={"post-login"} shouldShowShadow={false}/>
                </div>
                <div className="flex justify-between items-center gap-grid-sm">
                    <Button
                        iconOnly={true}
                        icon={announcements?.find(announcement => announcement?.read === false) === undefined ? "bell" : "bellWithDot"}
                        iconWidth={25}
                        iconHeight={24}
                        bgColor="bg-neutral-high-regular"
                        padding="p-[0px]"
                        iconStroke="stroke-neutral-low-regular"
                        shouldHover={false}
                        optionalStyle="min-w-[40px] flex justify-center items-center rounded-full hover:opacity-70 transition-all duration-fast ease-in"
                        onClick={handleNotificationsClick}
                    />
                    <Button
                        iconOnly={true}
                        icon={prefs?.theme === constant?.theme?.values?.[0] ? "moon" : "sun"}
                        iconWidth={24}
                        iconHeight={24}
                        bgColor="bg-neutral-high-regular"
                        padding="p-[0px]"
                        iconStroke="stroke-neutral-low-regular"
                        shouldHover={false}
                        optionalStyle="min-w-[40px] flex justify-center items-center rounded-full hover:opacity-70 transition-all duration-fast ease-in"
                        onClick={() => setter("prefs", { ...prefs, theme: prefs?.theme === constant?.theme?.values?.[0] ? "dark" : "light" }, "theme")}
                    />
                    <div className="hidden md:block h-[2.5rem] w-[1px] bg-neutral-high-dark transition-colors duration-fast ease-in"/>
                    <div>
                        <UserDropdown walletData={walletData} setSelected={(e) => handleUserDropdownClick(e)}/>
                    </div>
                </div>
            </div>
            <AnimatePresence>
                {shouldShowMenu && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        className="absolute w-screen h-screen top-0 start-0 flex lg:hidden backdrop-blur-md"
                    >
                        <SubMenu isResponsive={shouldShowMenu} toggleMenu={toggleMenu}/>
                    </motion.div>
                )}
            </AnimatePresence>

        </div>
    );
};

export default PostLoginHeader;