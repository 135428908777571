import React, { useContext, useEffect, useState } from "react";

import Label from "../view/component/label";

import { SessionContext } from "../util/context";
import { strings, secToMinSec } from "../util/formatter";
import OTPInputComponent from "../view/component/otpInput";
import Button from "../view/component/button";

const OTP = () =>
{
    const context = useContext(SessionContext);
    const { modalOptions } = context;
    const [otp, setOTP] = useState("");
    const [timer, setTimer] = useState(10);

    const onClickVerfiy = () =>
    {
        if (otp?.length === 6)
        {
            modalOptions?.onVerify?.();
        }
    };

    useEffect(() =>
    {
        const interval = setInterval(() =>
        {
            if (timer <= 0)
            {
                clearInterval(interval);
            }
            else
            {
                setTimer(timer - 1);
            }
        }, 1000);
        return () =>
        {
            clearInterval(interval);
        };
    }, [timer]);

    const resendOTP = () =>
    {
        setTimer(1000);
    };

    const OTPCounter = () =>
    {
        return (
            <div className="text-md text-center">
                {secToMinSec(timer) === "00:00"
                    ? <p>{strings("message.resendOTP")}</p>
                    : <p>{strings("common.otpExpiresIn")} <span className="text-primary-regular font-primary">{secToMinSec(timer)}</span></p>
                }
            </div>
        );
    };

    return (
        <>
            <div className="text-center pt-grid-md">
                <Label label={
                    <p>{strings(`label.${modalOptions?.verificationType === "email" ? "otpEmail" : "otpMobile"}`) }</p>
                }/>
                <div dir="ltr" className="pt-grid-md pb-grid-xs">
                    <OTPInputComponent
                        autoFocus
                        isNumberInput={true}
                        length={6}
                        className="flex flex-nowrap gap-grid-sm justify-center items-center"
                        inputClassName="w-[2.5rem] sm:w-[3rem] md:w-[3.5rem] py-grid-xs md:py-grid-sm rounded-xs bg-secondary-light text-secondary-darkest placeholder:text-secondary-darkest/50 focus:outline-secondary-darkest focus:outline-1 text-center"
                        onChangeOTP={(otp) => setOTP(otp)}
                    />
                </div>
            </div>
            <div className="py-grid-lg">
                <OTPCounter />
            </div>
            <div className="flex justify-between pt-grid-lg">
                <Button
                    text={strings("button.resendOTP")}
                    bgColor="bg-transparent"
                    textColor="text-secondary-darkest"
                    font="text-sm"
                    padding = "py-2 px-8"
                    border="border-2 border-secondary-darkest"
                    shouldHover = {false}
                    disabled = {secToMinSec(timer) !== "00:00"}
                    onClick={() => resendOTP()}
                />
                <Button
                    text={strings("button.next")}
                    padding = "py-2 px-10"
                    disabled = {otp?.length !== 6}
                    shouldHover = {otp?.length === 6}
                    onClick={() => onClickVerfiy()}
                />
            </div>
        </>
    );
};

export default OTP;