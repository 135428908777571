import React, { forwardRef, useContext } from "react";

import DatePicker, { registerLocale } from "react-datepicker";
import ar from "date-fns/locale/ar-SA";

import SVGIcon from "./svgIcons";

import { SessionContext } from "../../util/context";
import { getDate, strings } from "../../util/formatter";

import "react-datepicker/dist/react-datepicker.css";

registerLocale("ar", ar);

const DatePickerButton = (props) =>
{
    const { prefs, isPostLogin } = useContext(SessionContext);
    const { value, setValue } = props;
    // eslint-disable-next-line react/display-name
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <div ref={ref} onClick={onClick} className={`w-full flex justify-between items-center gap-grid-xxl text-sm ${isPostLogin ? "px-grid-sm py-[14px] bg-neutral-high-regular text-neutral-low-darkest" : "p-grid-sm bg-secondary-light text-secondary-darkest"} cursor-pointer rounded-xs`}>
            <div>
                {value ? getDate(prefs?.lang, value) : strings("placeholder.selectDate")}
            </div>
            <SVGIcon name="chevron" viewBox="0 0 24 24" width={14} height={14} stroke={isPostLogin ? "stroke-neutral-low-darkest" : "stroke-secondary-darkest"} className="rotate-90 fill-none"/>
        </div>
    ));
    return (
        <DatePicker
            wrapperClassName={"block w-full"}
            selected={value}
            locale={prefs?.lang}
            onChange={(date) => setValue(date)}
            customInput={<ExampleCustomInput />}
        />
    );
};

export default DatePickerButton;