import React, { createRef, useContext, useState } from "react";

import LineMsg from "./lineMsg";
import Label from "./label";
import Input from "./input";
import DropdownMenu from "./dropdownMenu";
import FileInput from "./fileInput";

import { getDate, getDateISO, strings } from "../../util/formatter";
import { validateFutureDate, validateNumbersOnly } from "../../util/validator";
import { SessionContext } from "../../util/context";
import DatePickerButton from "./datePickerButton";

export const CR_ISSUED_COUNTRY = [
    {
        id: 1,
        name: "المملكة العربية السعودية"
    }
];

const ManageEntityLegal = (props) =>
{
    const { prefs } = useContext(SessionContext);
    const {
        CR_ISSUED_COUNTRY,
        crNumber,
        selectedCountry,
        crExpiryDate,
        crFile,
        setCRNumber,
        setSelectedCRCountryCode,
        setCRExpiryDate,
        setCRFile
    } = props;
    const crFileRef = createRef(null);

    const selectCRFile = () =>
    {
        if (crFileRef.current !== null)
        {
            crFileRef.current.click();
        }
    };

    return (
        <>
            <div className="pb-grid-sm">
                <Label label={strings("label.commercialRegistrationNumber")}/>
                <Input
                    name="commercialRegistrationNumber"
                    value={crNumber}
                    setValue={setCRNumber}
                />
                <LineMsg msg={validateNumbersOnly(crNumber)?.msg} type="error"/>
            </div>
            <div className="flex justify-between gap-grid-xl">
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.commercialRegistrationIssuedCountry")}/>
                    <DropdownMenu
                        list={CR_ISSUED_COUNTRY}
                        labelKey="name"
                        placeholder={strings("placeholder.selectCountry")}
                        selectedValue={selectedCountry}
                        setSelected={setSelectedCRCountryCode}
                    />
                    <LineMsg type="error"/>
                </div>
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.commercialRegistrationExpiryDate")}/>
                    <DatePickerButton
                        value={Date.parse(crExpiryDate)}
                        setValue={setCRExpiryDate}
                    />
                    <LineMsg msg={validateFutureDate(getDate(prefs?.lang, crExpiryDate, "YYYY-MM-DD"))?.msg} type="error"/>
                </div>
            </div>
            <div>
                <Label label={strings("label.commercialRegistrationFile")}/>
                <FileInput
                    fileRef={crFileRef}
                    value={crFile}
                    setValue={setCRFile}
                    selectFile={selectCRFile}
                    acceptedTypes=".pdf"
                />
                <LineMsg type="error"/>
            </div>
        </>
    );
};

export default ManageEntityLegal;