import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import DropdownMenu from "../view/component/dropdownMenu";
import Button from "../view/component/button";
import Loader from "../view/component/loader";

import { SessionContext } from "../util/context";
import { getLangCountryURL, handleSEOURL, strings } from "../util/formatter";
import endpoint from "../config/endpoint";
import { request } from "../util/http";

const Directory = () =>
{
    const { setter, prefs, setActiveModal, setModalOptions } = useContext(SessionContext);
    const navigate = useNavigate();
    const [merchantList, setMerchantList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selected, setSelected] = useState({});

    const fetchMerchantList = async () =>
    {
        setIsLoading(true);
        try
        {
            const res = await request("GET", endpoint.crm.user.merchant);
            setMerchantList(res?.list);
            setIsLoading(false);
        }
        catch (error) {
            setIsLoading(false);
        }
    };

    const updateSelectedMerchant = async () =>
    {
        setIsLoading(true);
        try
        {
            const res = await request("POST", endpoint.crm.user.access, { merchant_id: selected?.merchant_id });
            const {
                access_token,
                refresh_token,
                wallet_id,
                country_code,
                role_code
            } = res?.info || {};
            setter("access_token", access_token);
            setter("refresh_token", refresh_token);
            setter("role_code", role_code);
            setter("wallet_id", wallet_id);
            setter("country_code", country_code);
            navigate(`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard/overview`);
        }
        catch (error) {
            setIsLoading(false);
        }
    };

    useEffect(() =>
    {
        if (Object.keys(selected)?.length > 0)
        {
            updateSelectedMerchant();
        }
    }, [selected]);

    useEffect(() =>
    {
        fetchMerchantList();
    }, []);

    return (
        <div className="h-[calc(100vh-182px)] md:h-[calc(100vh-132px)] flex justify-center items-center">
            {isLoading && <Loader />}
            <div className="w-[350px] flex flex-col gap-grid-md">
                <DropdownMenu
                    list={merchantList}
                    labelKey="display_name"
                    placeholder={strings("placeholder.selectEntity")}
                    selectedValue={selected}
                    setSelected={setSelected}
                    shadow="shadow-[0_20px_20px_0_rgba(0,0,0,0.3)]"
                    dropdownBG="bg-secondary-lightest"
                />
                <Button
                    text={strings("button.openNewEntityAccount")}
                    onClick={() =>
                    {
                        setActiveModal("createDirectory");
                        setModalOptions({
                            showStepsBar: true,
                            currentStep: 1,
                            totalSteps: 5,
                            title: strings("title.newEntity"),
                            hideCloseButton: true
                        });
                    }}
                    bgColor="bg-secondary-darkest"
                    hoverTextColor="hover:text-primary-lightest"
                    hoverBorder="hover:border-2 hover:border-primary-lightest"
                    font="text-md"
                    padding="py-grid-xs pe-grid-xs ps-grid-sm"
                    optionalStyle="text-center shadow-[0_20px_20px_0_rgba(0,0,0,0.3)]"
                />
            </div>
        </div>
    );
};

export default Directory;