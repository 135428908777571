import React, { useEffect, useState, useContext } from "react";

import { SessionContext } from "../util/context";
import { request } from "../util/http";
import { getDate, getDateISO } from "../util/formatter";
import endpoint from "../config/endpoint"

import Section from "../view/component/section";
import Section1 from "../view/component/section1";
import Section2 from "../view/component/section2";
import Section3 from "../view/component/section3";
import Section4 from "../view/component/section4";
import Section5 from "../view/component/section5";
import Section6 from "../view/component/section6";
import Section7 from "../view/component/section7";
import Section8 from "../view/component/section8";
import Section9 from "../view/component/section9";
import Section10 from "../view/component/section10";
import Loader from "../view/component/loader";

const LandingPage = () =>
{
    const { prefs } = useContext(SessionContext);

    const [pageNumber] = useState(1);
    const [productsChartState, setProductsChartState] = useState({});
    const [contributorsChartState, setContributorsChartState] = useState({});
    const [producersChartState, setProducersChartState] = useState({});
    const [isProductChartLoading, setIsProductChartLoading] = useState(false);
    const [isContributorChartLoading, setIsContributorChartLoading] = useState(false);
    const [isProducerChartLoading, setIsProducerChartLoading] = useState(false);

    const fetchProductsChart = async () =>
    {
        setIsProductChartLoading(true);
        try
        {
            const res = await request("GET", `${endpoint.bi.chart.data}?chart_code=product&period_code=week&snapshot_code=${getDate(prefs?.lang, undefined, "YYYY-MM-DD")}&cat_code=all&timestamp=${getDateISO()}&page=${pageNumber}`);
            setProductsChartState(res?.info);
            setIsProductChartLoading(false);
        }
        catch (error) {
            setIsProductChartLoading(false);
        }
    };

    const fetchContributorsChart = async () =>
    {
        setIsContributorChartLoading(true);
        try
        {
            const res = await request("GET", `${endpoint.bi.chart.data}?chart_code=contributor&period_code=week&snapshot_code=${getDate(prefs?.lang, undefined, "YYYY-MM-DD")}&timestamp=${getDateISO()}&page=${pageNumber}`);
            setContributorsChartState(res?.info);
            setIsContributorChartLoading(false);
        }
        catch (error) {
            setIsContributorChartLoading(false);
        }
    };

    const fetchProducersChart = async () =>
    {
        setIsProducerChartLoading(true);
        try
        {
            const res = await request("GET", `${endpoint.bi.chart.data}?chart_code=producer&period_code=week&snapshot_code=${getDate(prefs?.lang, undefined, "YYYY-MM-DD")}&timestamp=${getDateISO()}&page=${pageNumber}`);
            setProducersChartState(res?.info);
            setIsProducerChartLoading(false);
        }
        catch (error) {
            setIsProducerChartLoading(false);
        }
    };

    useEffect(() =>
    {
        fetchProductsChart();
        fetchContributorsChart();
        fetchProducersChart();
    }, []);

    return (
        <>
            {(isProductChartLoading || isContributorChartLoading || isProducerChartLoading) && <Loader />}
            <Section>
                <Section1 />
            </Section>
            <Section optionalStyle="min-h-auto">
                <Section2/>
            </Section>
            {!isProductChartLoading &&
                <Section id={"section-three"} optionalStyle="pt-[100px]">
                    <Section3 productsChartState={productsChartState} />
                </Section>
            }
            {!isContributorChartLoading &&
                <Section optionalStyle="pt-[100px]">
                    <Section4 contributorsChartState={contributorsChartState} />
                </Section>
            }
            {!isProducerChartLoading &&
                <Section id={"section-five"} optionalStyle="pt-[100px]">
                    <Section5 producersChartState={producersChartState} />
                </Section>
            }
            <Section optionalStyle="py-[100px] bg-primary-lightest">
                <Section6 />
            </Section>
            <Section optionalStyle="py-[100px] bg-primary-lightest">
                <Section7 />
            </Section>
            <Section optionalStyle="py-[100px] bg-primary-lightest">
                <Section8 />
            </Section>
            <Section id={"section-nine"} optionalStyle="py-[100px] bg-primary-lightest" useContainer={false}>
                <Section9 />
            </Section>
            <Section id={"section-ten"} optionalStyle="py-[100px] bg-primary-regular">
                <Section10 />
            </Section>
        </>
    );
};

export default LandingPage;