/* eslint-disable no-constant-condition */
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { AnimatePresence, motion } from "framer-motion";

import PostLoginHeader from "../view/component/postLoginHeader";
import SubMenu from "../view/component/subMenu";
import Loader from "../view/component/loader";

import useMediaQuery from "../hook/useMediaQuery";
import endpoint from "../config/endpoint";
import constant from "../config/constant";
import { SessionContext } from "../util/context";
import { getDateISO, getLangCountryURL, strings } from "../util/formatter";
import { request } from "../util/http";

const Dashboard = () =>
{
    const { setter, prefs, announcements, shouldShowBnr, animateOpacity, setShouldShowBnr, setDismissableBnr } = useContext(SessionContext);
    const location = useLocation();
    const [shouldShowMenu, setShouldShowMenu] = useState(false);
    const [walletData, setWalletData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const isLarge = useMediaQuery("(min-width: 1024px)");

    useLayoutEffect(() =>
    {
        if (document.getElementById("postLoginInnerContainer"))
        {
            document.getElementById("postLoginInnerContainer").scrollTo(0, 0);
        }
    }, [location.pathname]);

    const fetchMerchantData = async () =>
    {
        setIsLoading(true);
        try
        {
            const res = await request("GET", endpoint.crm.merchant.data);
            setter("merchant_info", JSON.stringify(res?.info));
            setIsLoading(false);
        }
        catch (error) {
            console.log(error?.msg)
            setIsLoading(false);
        }
    };

    const generateKey = (announcement) => `${announcement.created_at || ""}`;

    const fetchAnnouncementList = async () =>
    {
        setIsLoading(true);
        try
        {
            const res = await request("GET", `${endpoint.cms.announcement.list}?last_update=${getDateISO()}`);
            const temp = [];
            // TODO: remove id logic once you get id from BE
            for (var i = 0; i < res?.list.length; i++)
            {
                if (res?.list[i]?.type_code === "announcement")
                {
                    temp?.push({ type:"msg", type_code:res?.list[i]?.type_code, created_at:res?.list[i]?.created_at, msg:res?.list[i]?.msg });
                }
                else if (res?.list[i]?.type_code === "webhook_is_missing")
                {
                    temp?.push({ type:"danger", type_code:res?.list[i]?.type_code, created_at:res?.list[i]?.created_at, msg:strings("message.webhook_is_missing"), destinationType:"modal", distination:"manageEntity", options: { title: strings("common.manageEntity"), active: "integration" } });
                }
                else if (res?.list[i]?.type_code === "cr_is_missing")
                {
                    temp?.push({ type:"danger", type_code:res?.list[i]?.type_code, created_at:res?.list[i]?.created_at, msg:strings("message.cr_is_missing"), destinationType:"modal", distination:"manageEntity", options: { title: strings("common.manageEntity"), active: "legalEntity" } });
                }
                else if (res?.list[i]?.type_code === "cr_is_not_approved")
                {
                    temp?.push({ type:"msg", type_code:res?.list[i]?.type_code, created_at:res?.list[i]?.created_at, msg:strings("message.cr_is_not_approved") });
                }
                else if (res?.list[i]?.type_code === "cr_is_expired")
                {
                    temp?.push({ type:"danger", type_code:res?.list[i]?.type_code, created_at:res?.list[i]?.created_at, msg:strings("message.cr_is_expired"), destinationType:"modal", distination:"manageEntity", options: { title: strings("common.manageEntity"), active: "legalEntity" } });
                }
                else if (res?.list[i]?.type_code === "subscription_has_expired")
                {
                    temp?.push({ type:"danger", type_code:res?.list[i]?.type_code, created_at:res?.list[i]?.created_at, msg:strings("message.subscription_has_expired"), destinationType:"modal", distination:"manageEntity", options: { title: strings("common.manageEntity"), active: "subscriptions" } });
                }
                else if (res?.list[i]?.type_code === "email_not_verified")
                {
                    temp?.push({ type:"warning", type_code:res?.list[i]?.type_code, created_at:res?.list[i]?.created_at, msg:strings("message.email_not_verified"), destinationType:"modal", distination:"userAccount", options: { title: strings("common.myAccount") } });
                }
                else if (res?.list[i]?.type_code === "mobile_not_verified")
                {
                    temp?.push({ type:"warning", type_code:res?.list[i]?.type_code, created_at:res?.list[i]?.created_at, msg:strings("message.mobile_not_verified"), destinationType:"modal", distination:"userAccount", options: { title: strings("common.myAccount") } });
                }
            }

            if (temp?.length > 0)
            {
                // eslint-disable-next-line
                const updatedTemp = temp?.map((item) =>
                {
                    const key = generateKey(item);

                    const existingItem = announcements?.find(announcement => announcement?.id === key);

                    return {
                        ...item,
                        id: key,
                        read: existingItem ? existingItem.read : false
                    };
                });

                setter("announcements", JSON.stringify(updatedTemp));

                if (temp?.filter(item => item?.type?.toLowerCase() === "danger")?.[0])
                {
                    setShouldShowBnr(true);
                    setDismissableBnr(temp?.filter(item => item?.type?.toLowerCase() === "danger")?.[0]);
                }
            }
            setIsLoading(false);
        }
        catch (error) {
            setIsLoading(false);
        }
    };

    const fetchWalletData = async () =>
    {
        setIsLoading(true);
        try
        {
            const res = await request("GET", endpoint.fms.wallet.data);
            setWalletData(res?.info);
            setIsLoading(false);
        }
        catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() =>
    {
        if (isLarge)
        {
            setShouldShowMenu(false);
        }
    }, [isLarge]);

    useEffect(() =>
    {
        if (location.pathname === `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard` || location.pathname === `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard/`)
        {
            window.location = `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard/overview`;
        }
        else if (location.pathname === `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard/trend` || location.pathname === `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard/trend/`)
        {
            window.location = `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard/trend/product`;
        }
        else if (location.pathname === `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard/producer` || location.pathname === `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard/producer/`)
        {
            window.location = `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard/producer/product-list`;
        }
        else if (location.pathname === `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard/distributor` || location.pathname === `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard/distributor/`)
        {
            window.location = `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard/distributor/outlet-list`;
        }
    }, [location.pathname]);

    useEffect(() =>
    {
        fetchMerchantData();
        fetchAnnouncementList();
        fetchWalletData();
    }, []);

    return (
        <>
            {isLoading && <Loader />}
            {announcements?.some(item => constant.announcementFlags.includes(item?.type_code)) &&
                <div className="absolute top-0 start-0 h-full w-full bg-neutral-high-lightest/95 backdrop-blur-sm transition-colors duration-fast ease-in z-50" /> }
            <div className="flex relative box-border p-grid-xs md:p-grid-sm">
                {!shouldShowMenu && <SubMenu />}
                <div id="postLoginInnerContainer" data-lenis-prevent className={`${shouldShowBnr ? "h-[calc(100vh-56px)] md:h-[calc(100vh-72px)]" : "h-[calc(100vh-16px)] md:h-[calc(100vh-32px)]"} w-full relative bg-neutral-high-lightest transition-colors duration-fast ease-in rounded-md lg:rounded-e-md lg:rounded-s-none overflow-y-scroll overflow-x-clip`}>
                    <PostLoginHeader
                        walletData={walletData}
                        shouldShowMenu={shouldShowMenu}
                        toggleMenu={() => setShouldShowMenu(!shouldShowMenu)}
                    />
                    <Outlet/>
                    <AnimatePresence>
                        {animateOpacity &&
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.5 }}
                                className="absolute top-0 start-0 h-full w-full bg-neutral-high-lightest z-[70] rounded-xl"
                            />
                        }
                    </AnimatePresence>
                </div>
            </div>
        </>
    );
};

export default Dashboard;