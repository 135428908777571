import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import CountryLangDropdown from "./countryLangDropdown";
import CachedImg from "./cachedImg";
import SVGIcon from "./svgIcons";
import SearchInput from "./searchInput";

import constant from "../../config/constant";
import { strings, handleSEOURL, getLangCountryURL } from "../../util/formatter";
import { SessionContext } from "../../util/context";

import LOGO_LIGHT from "../../assets/image/logo_light.png";
import COUNTRY_SA from "../../assets/image/country_sa.webp";
import COUNTRY_AE from "../../assets/image/country_ae.png";

const Header = () =>
{
    const { prefs, showSearchInputInHeader, lenis, setActiveModal, modalOptions, setModalOptions, setter } = useContext(SessionContext);
    const location = useLocation();
    const navigate = useNavigate();
    const [scrollPosition, setScrollPosition] = useState(0);
    const [shouldShowMenu, setShouldShowMenu] = useState(false);
    const isTop = scrollPosition <= 70;
    const langCountryList = [
        {
            id: 1,
            country_code: "sa",
            country_label: strings("country.sa"),
            lang_country_label: prefs?.lang === constant.lang.values[0] ? strings("common.ar") : strings("common.en"),
            country_img: COUNTRY_SA,
            lang_list: [
                {
                    id: 1,
                    lang_code: "ar",
                    lang_label: strings("common.ar")
                },
                {
                    id: 2,
                    lang_code: "en",
                    lang_label: strings("common.en")
                }]
        },
        {
            id: 2,
            country_code: "ae",
            country_label: strings("country.ae"),
            lang_country_label: prefs?.lang === constant.lang.values[0] ? strings("common.ar") : strings("common.en"),
            country_img: COUNTRY_AE,
            lang_list: [
                {
                    id: 1,
                    lang_code: "ar",
                    lang_label: strings("common.ar")
                },
                {
                    id: 2,
                    lang_code: "en",
                    lang_label: strings("common.en")
                }]
        }
    ];
    const [selectedLangCountry, setSelectedLangCountry] = useState([]);

    const changeLanguage = async (firstItem, secondItem) =>
    {
        await setter("prefs", { ...prefs, lang: secondItem, country_code: firstItem }, "lang");
        window.location = handleSEOURL(location.pathname, secondItem, firstItem);
    };

    const handleScroll = () =>
    {
        const position = window.scrollY;
        setScrollPosition(position);
    };

    const toggleMenu = () =>
    {
        setShouldShowMenu(!shouldShowMenu);
    };

    const WhereTo = (item, type, id = "#section-nine") =>
    {
        if (shouldShowMenu)
        {
            setShouldShowMenu(!shouldShowMenu);
        }
        if (type === "navigate")
        {
            return;
        }
        if (type === "modal")
        {
            setActiveModal(item?.key);
            setModalOptions({ ...modalOptions, title: strings(`title.${item?.key}`) });
        }
        if (type === "scroll")
        {
            const isNotHomeRoute = location.pathname !== `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}` || location.pathname !== `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/`;

            if (isNotHomeRoute) navigate(`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}`);

            setTimeout(() =>
            {
                lenis.scrollTo(id, { offset: -220 });
            }, isNotHomeRoute ? 400 : 1);
        }
    };

    useEffect(() =>
    {
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () =>
        {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() =>
    {
        setSelectedLangCountry(langCountryList?.filter(item => item.country_code === (prefs?.country_code !== undefined ? prefs?.country_code : constant.country.values?.[0]))?.[0])
    }, [prefs?.country_code, prefs?.lang]);

    useEffect(() =>
    {
        if (shouldShowMenu)
        {
            lenis.stop();
        }
        else
        {
            lenis.start();
        }
    }, [shouldShowMenu]);

    return (
        <div className={`w-full fixed flex flex-col justify-center items-center top-0 z-50 transition-all duration-fast ease-in text-primary-lightest ${isTop ? "" : "bg-primary-regular shadow-[0_1px_3px_1px_rgba(var(--color-secondary-darkest),20%)]"}`}>
            <div className="w-full lg:container lg:max-w-screen-lg px-grid-lg lg:px-0 py-grid-sm lg:mx-auto flex gap-grid-xs">
                <div className={`w-full hidden lg:flex columns-auto justify-between items-center text-md`}>
                    {!constant.hide_footer_details_path?.some(path => location.pathname.includes(path)) &&
                    constant.main_page.sort((a, b) => a?.sortId - b?.sortId).map((item, index) =>
                    {
                        return (
                            <div key={item.key} className={`${index + 1 > constant.main_page?.length / 2 ? "text-end" : (prefs?.lang === "en" ? "pe-[19px] text-start" : "pe-[40px] text-start")}`}>
                                {item.key === "logo"
                                    ? (
                                        <Link
                                            to={`${getLangCountryURL(prefs?.lang, prefs?.country_code)}`}
                                        >
                                            <CachedImg src={LOGO_LIGHT} alt={strings("mofahres")} className="mx-auto w-[125px]"/>
                                        </Link>
                                    )
                                    : item.key === "langCountry"
                                        ? (
                                            <CountryLangDropdown
                                                list={langCountryList}
                                                outLabelKey="lang_country_label"
                                                inLabelKey="country_label"
                                                valueKey="country_code"
                                                imgKey="country_img"
                                                nestedList="lang_list"
                                                nestedListLabelKey="lang_label"
                                                nestedListValueKey="lang_code"
                                                xAxis="end-0"
                                                selectedValue={selectedLangCountry}
                                                setSelected={changeLanguage}
                                            />
                                        )
                                        : item.key === "producers" || item.key === "distributors"
                                            ? <div
                                                className="cursor-pointer transition-opacity duration-fast hover:opacity-70"
                                                onClick={() => WhereTo(item, "scroll", item.key === "producers" ? "#section-nine" : "#section-ten")}
                                            >
                                                {strings(item.title)}
                                            </div>
                                            : item.isModal
                                                ? <div
                                                    className="cursor-pointer transition-opacity duration-fast hover:opacity-70"
                                                    onClick={() => WhereTo(item, "modal")}
                                                >
                                                    {strings(item.title)}
                                                </div>
                                                : <Link
                                                    onClick={() => WhereTo(item, "navigate")}
                                                    className="transition-opacity duration-fast hover:opacity-70"
                                                    to={`${getLangCountryURL(prefs?.lang, prefs?.country_code)}/${item.path}`}
                                                >
                                                    {strings(item.title)}
                                                </Link>
                                }
                            </div>
                        );
                    })}
                    {constant.hide_footer_details_path?.some(path => location.pathname.includes(path)) && (
                        <>
                            <div className="min-w-[120px] text-start">
                                <Link
                                    to={`${getLangCountryURL(prefs?.lang, prefs?.country_code)}`}
                                >
                                    <CachedImg src={LOGO_LIGHT} alt={strings("mofahres")} className="mx-auto w-[125px]"/>
                                </Link>
                            </div>
                            <div className="min-w-[145px] text-end">
                                <CountryLangDropdown
                                    list={langCountryList}
                                    outLabelKey="lang_country_label"
                                    inLabelKey="country_label"
                                    valueKey="country_code"
                                    imgKey="country_img"
                                    nestedList="lang_list"
                                    nestedListLabelKey="lang_label"
                                    nestedListValueKey="lang_code"
                                    xAxis="end-0"
                                    selectedValue={selectedLangCountry}
                                    setSelected={changeLanguage}
                                />
                            </div>
                        </>
                    )
                    }
                </div>
                <div className={`w-full flex lg:hidden justify-between items-center`}>
                    <Link
                        to={`${getLangCountryURL(prefs?.lang, prefs?.country_code)}`}
                    >
                        <CachedImg src={LOGO_LIGHT} alt={strings("mofahres")} className="w-[120px]"/>
                    </Link>
                    <div className="cursor-pointer" onClick={toggleMenu}>
                        <SVGIcon name="hamburger" className="fill-primary-lightest" width={30} height={30} />
                    </div>
                </div>
            </div>
            {(constant.header_search_input_path.some(path => location.pathname.includes(path)) ||
            ((location.pathname === `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/` || location.pathname === `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}`) && showSearchInputInHeader === false)) &&
                <div className="w-full lg:container lg:max-w-screen-lg px-grid-lg lg:px-0 lg:mx-auto py-grid-md">
                    <SearchInput shouldShowShadow={isTop}/>
                </div>
            }
            <AnimatePresence>
                {shouldShowMenu && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        className="absolute w-screen h-screen top-0 start-0 flex lg:hidden backdrop-blur-md"
                    >
                        <motion.div
                            initial={{ translateX: 375 }}
                            animate={{ translateX: 0 }}
                            exit={{ translateX: 375 }}
                            transition={{ duration: 0.5 }}
                            className="min-w-[375px] h-screen absolute top-0 start-0 bg-primary-dark"
                        >
                            <div className="flex justify-between items-start p-grid-sm">
                                <CachedImg src={LOGO_LIGHT} alt={strings("mofahres")} className="w-[100px]"/>
                                <div className="cursor-pointer" onClick={toggleMenu}>
                                    <SVGIcon name={"close"} stroke="stroke-primary-lightest" width={30} height={30} />
                                </div>
                            </div>
                            <div data-lenis-prevent className="h-full flex flex-col justify-start items-center pb-[95%] gap-grid-xl overflow-y-scroll">
                                {constant.main_page?.sort((a, b) => a?.sortId - b?.sortId).map((item) =>
                                {
                                    return (
                                        item.key === "logo"
                                            ? null
                                            : <div key={item.key} className={`${item.key === "langCountry" ? "min-w-[145px] max-w-[145px]" : "min-w-full"} text-center`}>
                                                {item.key === "langCountry"
                                                    ? (
                                                        <CountryLangDropdown
                                                            list={langCountryList}
                                                            outLabelKey="lang_country_label"
                                                            inLabelKey="country_label"
                                                            valueKey="country_code"
                                                            imgKey="country_img"
                                                            nestedList="lang_list"
                                                            nestedListLabelKey="lang_label"
                                                            nestedListValueKey="lang_code"
                                                            xAxis="-end-1/2"
                                                            selectedValue={selectedLangCountry}
                                                            setSelected={changeLanguage}
                                                        />
                                                    )
                                                    : item.key === "producers" || item.key === "distributors"
                                                        ? <div
                                                            className="cursor-pointer transition-opacity duration-fast hover:opacity-70"
                                                            onClick={() => WhereTo(item, "scroll", item.key === "producers" ? "#section-nine" : "#section-ten")}
                                                        >
                                                            {strings(item.title)}
                                                        </div>
                                                        : item.isModal
                                                            ? <div
                                                                className="cursor-pointer transition-opacity duration-fast hover:opacity-70"
                                                                onClick={() => WhereTo(item, "modal")}
                                                            >
                                                                {strings(item.title)}
                                                            </div>
                                                            : <Link
                                                                onClick={() => WhereTo(item, "navigate")}
                                                                className="transition-opacity duration-fast hover:opacity-70"
                                                                to={`${getLangCountryURL(prefs?.lang, prefs?.country_code)}/${item.path}`}
                                                            >
                                                                {strings(item.title)}
                                                            </Link>
                                                }
                                            </div>
                                    );
                                })}
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default Header;