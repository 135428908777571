import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import GlobalContext from "./util/context.js";
import "./util/i18n.js";

import App from "./App";

import 'react-notifications-component/dist/theme.css'
import "./assets/style/global.css";
import "./assets/style/override.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
        <GlobalContext>
            <App />
        </GlobalContext>
    </BrowserRouter>
);