import React, { useContext } from "react";

import LoadingSkeleton from "./loadingSkeleton";

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";

import { SessionContext } from "../../util/context";
import constant from "../../config/constant";

const LineChartView = (props) =>
{
    const { prefs } = useContext(SessionContext);
    const { loading = false, data, labelKey, valueKey } = props;

    return (
        <ResponsiveContainer width="100%" height={250}>
            {
                loading
                ? <LoadingSkeleton rows="5" rowHeight="h-[21px]" rowWidth = "w-full"/>
                : <LineChart
                    data={data}
                    margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={labelKey} tick={{ fill: prefs?.theme === "dark" ? "#fff" : "#000" }}/>
                    <YAxis dataKey={valueKey} tick={<CustomizedYAxisTick prefs={prefs}/>} />
                    <Tooltip content={<CustomTooltip prefs={prefs} value={valueKey}/>} />
                    <Line type="monotone" dataKey={valueKey} stroke={constant?.chart_colors?.[0]} />
                </LineChart>
            }
        </ResponsiveContainer>
    );
};

const CustomizedYAxisTick = ({ prefs, x, y, payload }) =>
{
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0}
                textAnchor="start"
                fill={prefs?.theme === "dark" ? "#fff" : "#000"}>{new Intl.NumberFormat().format(payload?.value)}</text>
        </g>
    );
};

const CustomTooltip = ({ prefs, active, payload, label, value }) =>
{
    if (active && payload && payload.length)
    {
        return (
            <div className="color-background-bg p-1" style={{ border: `1px solid ${prefs?.theme === "dark" ? "#fff" : "#000"}` }}>
                <p className="label">{`${label} (${payload[0]?.payload?.[value]})`}</p>
            </div>
        );
    }
};

export default LineChartView;