import React, { useContext, useEffect, useRef, useState } from "react";

import dayjs from "dayjs";

import Button from "../view/component/button";
import Tabs from "../view/component/tabs";
import ManageWalletDeposit from "../view/component/manageWalletDeposit";
import ManageWalletWithdrawal from "../view/component/manageWalletWithdrawal";
import ManageWalletRequests from "../view/component/manageWalletRequests";

import endpoint from "../config/endpoint";
import { request } from "../util/http";
import { SessionContext } from "../util/context";
import { priceToPriceWithCurrency, strings } from "../util/formatter";
import { isNotEmpty, validateAmount } from "../util/validator";
import { showNotification } from "../util/notification";

const ManageWallet = () =>
{
    const { prefs, walletId, setActiveModal, modalOptions, setModalOptions } = useContext(SessionContext);
    const hiddenButtonRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [active, setActive] = useState("deposit");
    const [balanceHistory, setBalanceHistory] = useState({});
    const [walletForm, setWalletForm] = useState({
        deposit:
        {
            amount: 100
        },
        withdrawal:
        {
            amount: 0,
            selectedAccountDetails: {}
        }
    });
    const [accountList, setAccountList] = useState([]);

    const validForm = () =>
    {
        if (active === "deposit" && walletForm?.deposit?.amount !== 0 && walletForm?.withdrawal?.amount !== "" && validateAmount(walletForm?.deposit?.amount)?.isValid)
        {
            return false;
        }

        if (active === "withdrawal" && walletForm?.withdrawal?.amount !== 0 && walletForm?.withdrawal?.amount !== "" && validateAmount(walletForm?.withdrawal?.amount)?.isValid &&
            isNotEmpty(walletForm?.withdrawal?.selectedAccountDetails?.bank_account_number)
        )
        {
            return false;
        }

        return true
    }

    const fetchBankAccountList = async () =>
    {
        try
        {
            const res = await request("GET", endpoint.fms.bankAccount.list);
            setAccountList(res?.list);
        }
        catch (error) {}
    }

    const fetchHistoryBalance = async () =>
    {
        try
        {
            const res = await request("GET", `${endpoint.fms.history.balance}?year=${dayjs().year()}&month=${dayjs().month() + 1}`);
            setBalanceHistory(res);
        }
        catch (error) {}
    }

    const pay = async () =>
    {
        if (active === "deposit")
        {
            const button = document.querySelector(".mysr-form-button");
            if (button)
            {
                hiddenButtonRef.current = button;
                if (hiddenButtonRef.current)
                {
                    hiddenButtonRef.current.click();
                }
            }
        }
        if (active === "withdrawal")
        {
            setIsLoading(true);
            try
            {
                const data =
                {
                    amount: walletForm?.withdrawal?.amount,
                    bank_account_id: walletForm?.withdrawal?.selectedAccountDetails?.bank_account_id
                }
                const res = await request("POST", endpoint.fms.debit.directDeposit, data);
                setIsLoading(false);
                setActiveModal(undefined);
                setTimeout(() =>
                {
                    window.location.reload();
                }, 1000);
            }
            catch (error)
            {
                setIsLoading(false);
                showNotification("error", strings("message.error"), error?.error_msg, prefs?.dir);
            }
        }
    }

    useEffect(() =>
    {
        setWalletForm({
            deposit:
            {
                amount: 100
            },
            withdrawal:
            {
                amount: 0,
                accountDetails: {}
            }
        });
    }, [active]);

    useEffect(() =>
    {
        if (active === "deposit")
        {
            window?.Moyasar.init(
            {
                element: '.form-payment-credit-wallet',
                language: prefs?.lang,
                amount: walletForm?.deposit?.amount * 100,
                currency: 'SAR',
                description: 'Wallet Topup',
                publishable_api_key: process.env.REACT_APP_MOYASAR_API_KEY,
                callback_url: 'https://rest.mofahres.com/v1/fms/credit/redirection',
                methods: ['creditcard'],
                supported_networks: ['mada', 'visa', 'mastercard', 'amex'],
                metadata:
                {
                    wallet_id: walletId,
                    callback_type: 'topup'
                }
            });
        }
    }, [active]);

    useEffect(() =>
    {
        fetchBankAccountList();
        fetchHistoryBalance();
    }, []);

    return (
        <>
            <Tabs
                active={active}
                setActive={setActive}
                isModal={true}
                list={[
                    { key: "deposit", label: strings("common.deposit") },
                    { key: "withdrawal", label: strings("common.withdrawal") },
                    { key: "requestsInProgress", label: strings("common.requestsInProgress") }
                ]}
                shouldShowAll={false}
            >
                <div className="p-grid-md text-neutral-low-darkest font-secondary">
                    {(active === "deposit" || active === "withdrawal") &&
                        <div className="text-center">
                            <p className="text-sm pb-grid-sm">
                                {strings("common.availableBalance")}
                            </p>
                            <p className="font-primary text-lg pb-grid-sm">
                                {priceToPriceWithCurrency(prefs?.currency, modalOptions?.walletData?.balance ? modalOptions?.walletData?.balance : 0)}
                            </p>
                        </div>
                    }
                    {active === "deposit"
                        ? <ManageWalletDeposit walletForm={walletForm} setWalletForm={setWalletForm}/>
                        : active === "withdrawal"
                        ? <ManageWalletWithdrawal accountList={accountList} walletForm={walletForm} setWalletForm={setWalletForm}/>
                        : <ManageWalletRequests balanceHistory={balanceHistory}/>
                    }
                </div>
            </Tabs>
            {(active === "deposit" || active === "withdrawal") &&
                <div className={`flex ${active === "withdrawal" ? "justify-between" : "justify-end"} mx-grid-md pb-grid-md`}>
                    {active === "withdrawal" &&
                    <Button
                        disabled={false}
                        text={strings("button.manageBankAccounts")}
                        bgColor={"bg-transparent"}
                        hoverBGColor={"hover:bg-neutral-low-darkest"}
                        textColor={"text-neutral-low-darkest"}
                        hoverTextColor={"hover:text-neutral-high-lightest"}
                        hoverBorder={"hover:border-2 hover:border-transparent"}
                        border={"border-2 border-neutral-low-darkest"}
                        padding="py-2 px-10"
                        onClick={() => {
                            setActiveModal("manageBankAccounts");
                            setModalOptions({
                                title: strings("title.manageBankAccounts")
                            });
                        }}
                    />
                    }
                    <Button
                        disabled={validForm() || isLoading}
                        text={active === "withdrawal" ? `${strings("button.withdraw")} ${priceToPriceWithCurrency(prefs?.currency, walletForm?.withdrawal?.amount)}` : `${strings("button.pay")} ${priceToPriceWithCurrency(prefs?.currency, walletForm?.deposit?.amount)}`}
                        padding="py-2 px-grid-sm"
                        shouldHover={!validForm()}
                        onClick={pay}
                    />
                </div>
            }
        </>
    );
};

export default ManageWallet;