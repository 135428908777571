import React, { useContext, useState } from "react";

import { useNavigate } from "react-router-dom";

import Label from "../view/component/label";
import Input from "../view/component/input";
import LineMsg from "../view/component/lineMsg";
import Button from "../view/component/button";

import { SessionContext } from "../util/context";
import { getLangCountryURL, handleSEOURL, strings } from "../util/formatter";
import { isNotEmpty } from "../util/validator";
import endpoint from "../config/endpoint";
import { request } from "../util/http";

const Login = () =>
{
    const { prefs, setActiveModal, modalOptions, setModalOptions, setter } = useContext(SessionContext);
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loginErrorMsg, setLoginErrorMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const isValidForm = isNotEmpty(email) && isNotEmpty(password);

    const onResetPasswordClick = (isResetPasswordRequired = false) =>
    {
        setActiveModal("resetPassword");
        const resetPasswordOptions = isResetPasswordRequired
        ? {
            currentStep: 2,
            email: email
        }
        : {};
        setModalOptions({
            ...modalOptions,
            ...resetPasswordOptions,
            title: strings("title.resetPassword")
        });
    };

    const onClickVerify = () =>
    {
        setActiveModal(undefined);
        navigate(`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/directory`);
    };

    const onVerifyRequired = (option, destinataion) =>
    {
        const OTPOptions = option === "email" || option === "mobile"
        ? {
            verificationType: option
        }
        : {
            destinataion
        };
        setModalOptions({
            ...modalOptions,
            ...OTPOptions,
            title: strings("title.otp"),
            onVerify: onClickVerify
        });
        setActiveModal("otp");
    };

    const login = async () =>
    {
        if (isValidForm)
        {
            setLoginErrorMsg("");
            setIsLoading(true);
            try
            {
                const postData =
                {
                    email,
                    password,
                    identifier_type: "email",
                    authorization_type: "sms"
                };

                const res = await request("POST", endpoint.crm.user.token, postData);

                const {
                    is_password_change_required,
                    is_email_verification_required,
                    is_mobile_verification_required,
                    is_2fa_required,
                    otp_destination_type,
                    access_token,
                    refresh_token,
                    user_info = {}
                } = res?.info || {};

                setter("access_token", access_token);
                setter("refresh_token", refresh_token);
                setter("user_info", JSON.stringify(user_info));

                if (is_password_change_required)
                {
                    return onResetPasswordClick(true);
                }

                if (is_email_verification_required || is_mobile_verification_required)
                {
                    return onVerifyRequired(res?.info?.is_email_verification_required ? "email" : "mobile");
                }

                if (is_2fa_required)
                {
                    return onVerifyRequired("otp", otp_destination_type);
                }

                fetchUserData();
            }
            catch (error) {
                setLoginErrorMsg(error?.error_msg);
                setIsLoading(false);
            }
        }
    };

    const fetchUserData = async () =>
    {
        try
        {
            const res = await request("GET", endpoint.crm.user.data);
            await setter("user_info", JSON.stringify(res?.info));
            await setter("prefs", { ...prefs, lang: res?.info?.lang_code, country_code: prefs?.country_code }, "lang");
            setActiveModal(undefined);
            setModalOptions({});
            navigate(handleSEOURL(`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/directory`, res?.info?.lang_code, prefs?.country_code))
        }
        catch (error) {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="pb-4">
                <Label label={strings("label.email")}/>
                <Input
                    name="email"
                    value={email}
                    placeholder={strings("placeholder.email")}
                    setValue={setEmail}
                />
                <LineMsg msg={loginErrorMsg} type="error"/>
            </div>
            <div className="pb-4">
                <Label label={strings("label.password")}/>
                <Input
                    name="password"
                    value={password}
                    type="password"
                    placeholder={strings("placeholder.password")}
                    setValue={setPassword}
                />
            </div>
            <div className="flex justify-between gap-grid-xs pt-grid-lg">
                <Button
                    disabled={isLoading}
                    text={strings("button.resetPassword")}
                    bgColor="bg-transparent"
                    hoverBGColor="hover:bg-secondary-darkest"
                    textColor="text-secondary-darkest"
                    hoverTextColor="hover:text-primary-lightest"
                    font="text-sm"
                    padding = "py-2 px-8"
                    border="border-2 border-secondary-darkest"
                    hoverBorder="hover:border-2 hover:border-transparent"
                    shouldHover = {true}
                    onClick={() => onResetPasswordClick()}
                />
                <Button
                    disabled={!isValidForm || isLoading}
                    text={strings("button.login")}
                    bgColor="bg-secondary-darkest"
                    onClick={login}
                />
            </div>
        </>
    );
};

export default Login;