import React from "react";

import Label from "./label";
import Input from "./input";
import LineMsg from "./lineMsg";
import DropdownMenu from "./dropdownMenu";

import { strings } from "../../util/formatter";
import { validateAmount } from "../../util/validator";

const ManageWalletWithdrawal = ({ accountList, walletForm, setWalletForm }) =>
{
    const {
        withdrawal
    } = walletForm || {};

    const {
        amount,
        selectedAccountDetails
    } = withdrawal || {};

    const onValueChange = (value, key) =>
    {
        setWalletForm({ ...walletForm, withdrawal: { ...withdrawal, [key]: value } });
    };

    return (
        <>
            <div className="pb-grid-sm">
                <Label label={strings("label.amount")}/>
                <Input
                    validateLength={true}
                    maxLength={6}
                    name="amount"
                    value={amount}
                    setValue={(e) => { onValueChange(e, "amount") }}
                />
                <LineMsg msg={validateAmount(walletForm?.deposit?.amount)?.msg} type="error"/>
            </div>
            <div className="w-full">
                <Label label={strings("label.bankAccount")}/>
                <DropdownMenu
                    list={accountList}
                    labelKey="bank_account_number"
                    placeholder={strings("placeholder.selectBankAccount")}
                    selectedValue={selectedAccountDetails}
                    setSelected={(e) => { onValueChange(e, "selectedAccountDetails") }}
                />
                <LineMsg type="error"/>
            </div>
        </>
    );
};

export default ManageWalletWithdrawal;