import React, { useContext, useEffect, useState } from "react";

import dayjs from "dayjs";

import Table from "../view/component/table";
import DropdownMenu from "../view/component/dropdownMenu";
import DateRangeByFilter from "../view/component/dateRangeByFilter";

import constant from "../config/constant";
import endpoint from "../config/endpoint";
import { SessionContext } from "../util/context";
import { getDate, getDateISO, getPreviousTuesday, strings } from "../util/formatter";
import { request } from "../util/http";
import { isTuesday } from "../util/validator";

const TrendProducer = () =>
{
    const { prefs, setActiveModal, setModalOptions } = useContext(SessionContext);
    const [pageNumber, setPageNumber] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [chartInfo, setChartInfo] = useState({});
    const [dateRangeValue, setDateRangeValue] = useState(constant.date_range[0]);
    const [date, setDate] = useState(dayjs(getPreviousTuesday())?.toDate());
    const [isMaxPageNumber, setIsMaxPageNumber] = useState(false);

    const loadMore = (type) =>
    {
        if (type === "next")
        {
            setPageNumber(prevState => prevState + 1);
        }
        else
        {
            setPageNumber(prevState => prevState - 1);
        }
    };

    const openProducerModal = (item) =>
    {
        setActiveModal("producer");
        setModalOptions({
            item,
            title: item?.title_main
        });
    };

    const fetchChartData = async () =>
    {
        setIsLoading(true);
        try
        {
            const res = await request("GET", `${endpoint.bi.chart.data}?chart_code=producer&period_code=${dateRangeValue?.value}&snapshot_code=${getDate(prefs?.lang, date, "YYYY-MM-DD")}&timestamp=${getDateISO()}&page=${pageNumber}`);
            if (res?.info?.list?.length === 0)
            {
                setIsMaxPageNumber(true);
            }
            else
            {
                setChartInfo(res?.info);
            }
            setIsLoading(false);
        }
        catch (error) {
            console.log(error)
            setIsLoading(false);
        }
    };

    useEffect(() =>
    {
        setPageNumber(1);
        fetchChartData();
    }, [date]);

    useEffect(() =>
    {
        fetchChartData();
    }, [pageNumber]);

    useEffect(() =>
    {
        fetchChartData();
    }, []);

    return (
        <div className="p-grid-md">
            <div className="flex flex-col lg:flex-row justify-between items-start lg:items-end gap-grid-md pt-grid-md pb-grid-lg">
                <p className="pb-grid-xs lg:pb-0 text-lg font-primary text-neutral-low-dark transition-colors duration-fast ease-in">
                    {strings("title.trendProducer")}
                </p>
                <div className="flex self-end items-center">
                    <DropdownMenu
                        list={constant.date_range}
                        labelKey={prefs?.lang === constant.lang.values[0] ? "label_ar" : "label_en"}
                        selectedValue={dateRangeValue}
                        setSelected={setDateRangeValue}
                        gap="gap-grid-sm sm:gap-grid-xl"
                        wrapperStyle="me-grid-sm"
                    />
                    <DateRangeByFilter
                        isDisabled={isLoading}
                        shouldFilterDateByDay={dateRangeValue?.value === "week" && isTuesday}
                        showMonthYearPicker={dateRangeValue?.value === "month"}
                        showQuarterYearPicker={dateRangeValue?.value === "quarter"}
                        showYearPicker={dateRangeValue?.value === "year"}
                        showTimeSelect={false}
                        inputClassName="ps-grid-sm bg-neutral-high-regular hover:bg-transparent text-neutral-low-darkest border-2 border-transparent hover:border-neutral-low-darkest transition-colors duration-fast ease-in"
                        value={date}
                        setDate={setDate}
                    />
                </div>
            </div>
            <Table
                loading={isLoading}
                titles={chartInfo?.list?.length > 0 ? Object.keys(chartInfo?.list?.[0])?.filter(column => !["id"]?.includes(column)) : []}
                tableData={chartInfo?.list?.filter(column => !["id"]?.includes(column))}
                responsive={
                    {
                        maxXL: ["position_current", "title_main", "value", "position_previous", "period_in_position", "period_in_chart"],
                        maxLG: ["position_current", "title_main", "value", "position_previous"],
                        maxMD: ["position_current", "title_main", "value", "position_previous"],
                        maxSM: ["position_current", "title_main", "value"],
                        maxXS: ["position_current", "title_main", "value"]
                    }
                }
                sort={["position_current", "title_main", "value", "position_previous", "period_in_position", "period_in_chart"]}
                columnType={{ clickable: ["title_main"] }}
                onClickKeys={
                    {
                        title_main: openProducerModal
                    }
                }
                pageNumber={pageNumber}
                isMaxPageNumber={isMaxPageNumber}
                loadMore={loadMore}
            />
        </div>
    );
};

export default TrendProducer;