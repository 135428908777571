import React, { useEffect, useState } from "react";

import Button from "../view/component/button";
import Tabs from "../view/component/tabs";
import ManageBankAccountsList from "../view/component/manageBankAccountsList";
import ManageBankAccountsCreate from "../view/component/manageBankAccountsCreate";

import endpoint from "../config/endpoint";
import { request } from "../util/http";
import { strings } from "../util/formatter";
import { isNotEmpty, validateLettersWithSpaces, validateNumbersOnly } from "../util/validator";

const ManageBankAccounts = () =>
{
    const [active, setActive] = useState("accountList");
    const [isLoading, setIsLoading] = useState(false);
    const [accountList, setAccountList] = useState([]);
    const [bankList, setBankList] = useState([]);
    const [accountForm, setAccountForm] = useState({
        selectedBank: {},
        accountNumber: "",
        IBANNumber: "",
        holderName: ""
    });

    const validForm = () =>
    {
        if (active === "createBankAccount" && validateNumbersOnly(accountForm?.accountNumber)?.isValid &&
            validateNumbersOnly(accountForm?.IBANNumber)?.isValid && validateLettersWithSpaces(accountForm?.holderName, "en")?.isValid &&
            isNotEmpty(accountForm?.selectedBank?.bank_code) && isNotEmpty(accountForm?.accountNumber) && isNotEmpty(accountForm?.IBANNumber) &&
            isNotEmpty(accountForm?.holderName)
        )
        {
            return true;
        }

        return false
    }

    const fetchBankList = async () =>
    {
        setIsLoading(true);
        try
        {
            const res = await request("GET", endpoint.cms.bank.list);
            setBankList(res?.list);
            setIsLoading(false);
        }
        catch (error)
        {
            setIsLoading(false);
        }
    }

    const fetchBankAccountList = async () =>
    {
        setIsLoading(true);
        try
        {
            const res = await request("GET", endpoint.fms.bankAccount.list);
            setAccountList(res?.list);
            setIsLoading(false);
        }
        catch (error)
        {
            setIsLoading(false);
        }
    }

    const createBankAccount = async () =>
    {
        if (validForm)
        {
            setIsLoading(true);
            try
            {
                const data = {
                    bank_code : accountForm?.selectedBank?.bank_code,
                    bank_account_number : accountForm?.accountNumber,
                    bank_iban_number : accountForm?.IBANNumber,
                    holder_name : accountForm?.holderName
                }
                const res = await request("POST", endpoint.fms.bankAccount.data, data);
                await fetchBankAccountList();
                setActive("accountList");
            }
            catch (error)
            {
                setIsLoading(false);
            }
        }
    }

    useEffect(() =>
    {
        setAccountForm({
            selectedBank: {},
            accountNumber: "",
            IBANNumber: "",
            holderName: ""
        });
    }, [active]);

    useEffect(() =>
    {
        fetchBankList();
        fetchBankAccountList();
    }, []);

    return (
        <>
            <Tabs
                active={active}
                setActive={setActive}
                isModal={true}
                list={[
                    { key: "accountList", label: strings("common.accountList") },
                    { key: "createBankAccount", label: strings("common.createBankAccount") }
                ]}
                shouldShowAll={false}
            >
                <div className="p-grid-md text-neutral-low-darkest font-secondary">
                    {active === "accountList"
                        ? <ManageBankAccountsList isLoading={isLoading} accountList={accountList} bankList={bankList}/>
                        : <ManageBankAccountsCreate bankList={bankList} accountForm={accountForm} setAccountForm={setAccountForm}/>
                    }
                </div>
            </Tabs>
            {active === "createBankAccount" &&
                <div className="flex justify-end mx-grid-md pb-grid-md">
                    <Button
                        disabled={!validForm() || isLoading}
                        text={strings("button.save")}
                        padding="py-2 px-grid-xl"
                        shouldHover={validForm() || !isLoading}
                        onClick={createBankAccount}
                    />
                </div>
            }
        </>
    );
};

export default ManageBankAccounts;